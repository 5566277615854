<template>
  <div class="order-container">
    <div class="search-section">
      <div class="search-content">
        <el-input
          v-model="querys.code"
          @clear="clearQuery"
          size="mini"
          clearable
          class="search-input"
          placeholder="多个订单号用逗号或换行符分隔"
          style="width: 50rem;"
        >
          <template slot="prefix">
            <i class="el-icon-document"></i>
          </template>
        </el-input>
        <el-input
          v-model="querys.rem"
          @clear="clearQuery"
          size="mini"
          clearable
          class="search-input"
          placeholder="备注"
        >
          <template slot="prefix">
            <i class="el-icon-notebook-2"></i>
          </template>
        </el-input>
        <el-button type="primary" size="mini" icon="el-icon-search" @click="getData">
          {{ $t('domestic.search') }}
        </el-button>
        <el-badge :hidden="!badgeNum" :value="badgeNum" class="filter-badge">
          <el-button type="info" plain size="mini" icon="el-icon-s-operation" @click="showFilter">
            筛选
          </el-button>
        </el-badge>
        <el-button type="warning" plain size="mini" icon="el-icon-refresh" @click="resetForm">
          重置
        </el-button>
        <download-excel
          class="export-excel-wrapper"
          :data="multipleSelection"
          :fields="fields"
          :name="timestampName"
          worksheet="sheet1"
        >
          <el-button type="success" plain size="mini" icon="el-icon-folder-opened">
            导出
          </el-button>
        </download-excel>
      </div>
    </div>
    <div class="status-indicators">
      <div class="status-item">
        <div class="status-dot warning-ball"></div>
        <span>缺货</span>
      </div>
      <div class="status-item">
        <div class="status-dot half-warning-ball"></div>
        <span>部分缺货</span>
      </div>
      <div class="status-item">
        <div class="status-dot success-ball"></div>
        <span>库存充足</span>
      </div>
      <div class="status-item">
        <div class="status-dot info-ball"></div>
        <span>未绑定库存</span>
      </div>
    </div>
    <el-table
        :data="tableData"
        border
        ref="multipleTable"
        :height="tableHeight"
        :max-height="tableHeight"
        @selection-change="handleSelectionChange"
        :row-class-name="tableRowClassName"
        style="width: 100%"
        default-expand-all
        v-loading="loading"
        class="custom-table"
    >
      <el-table-column
          type="selection"
          width="55"></el-table-column>
      <el-table-column type="expand" class-name="expand-row">
        <template slot-scope="scope">
          <el-table
              :data="scope.row.OrderItemDetails"
              border
              :row-class-name="scope.row.IsCancel ? 'canceled-detail-row' : 'success-row'"
              :header-cell-class-name="scope.row.IsCancel ? 'canceled-header-row' : 'header-row'"
              style="width: 100%"
          >
            <el-table-column label="商品标题" prop="ItemName" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="品名/规格" prop="GoodsName" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="照片" align="center" width="80px">
              <template slot-scope="slotScope">
                <el-image class="shopee-img" :preview-src-list="scope.row.OrderItemDetails.map(item => { return item.Img })" :src="slotScope.row.Img" v-if="slotScope.row.Img"></el-image>
              </template>
            </el-table-column>
            <el-table-column label="SKU" prop="Sku" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="数量" prop="Amount" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="价格" prop="Price" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="库存扣除状态" show-overflow-tooltip align="center">
              <template slot-scope="slotScope">
                <span>{{ slotScope.row.DeductionTime ? '已扣除' : '未扣除' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="库存状态" prop="StockStateTxt" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="库存数量/冻结数量" show-overflow-tooltip align="center">
              <template slot-scope="slotScope">
                <span class="stock-link" @click="showStockQuantityDetail(slotScope.row.Id)">
                  {{ `${slotScope.row.StockQuantity}/${slotScope.row.FrozenQuantity}` }}
                  <i class="el-icon-view"></i>
                </span>
              </template>
            </el-table-column>
            <el-table-column label="可出货数量" prop="ShippingQuantity" show-overflow-tooltip align="center"></el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column label="订单号" prop="MainBillCode" show-overflow-tooltip align="center" width="180">
        <template slot-scope="scope">
          <div class="u-start-flex">
            <div class="mr8" :class="getClasses(scope.row.StockState)"></div>
            <div>{{ scope.row.MainBillCode }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="订单状态" prop="OrderStatu" :formatter="orderStatusFormatter" align="center" width="100"></el-table-column>
      <el-table-column label="店铺名称" show-overflow-tooltip prop="ShopeeName" align="center" width="150"></el-table-column>
      <el-table-column :label="$t('order.warehouse')" prop="WareHouseName" align="center"></el-table-column>
      <el-table-column :label="$t('order.carrier')" width="135" show-overflow-tooltip prop="CarrierName" align="center"></el-table-column>
      <el-table-column show-overflow-tooltip prop="CarrierBillCode" :label="$t('order.carrierbillcode')" align="center" width="130px">
      </el-table-column>
      <el-table-column :label="$t('order.timer')" show-overflow-tooltip prop="TimerName" align="center"></el-table-column>
      <el-table-column :label="$t('order.goodstype')" prop="GoodsTypeName" align="center"></el-table-column>
      <el-table-column label="派件人" prop="DeliveryName" align="center"></el-table-column>
      <el-table-column label="物流状态" show-overflow-tooltip prop="TrackContent" align="center"></el-table-column>
      <el-table-column :label="$t('order.Weight')" prop="Weight" align="center"></el-table-column>
      <el-table-column label="下单时间" width="160" prop="CreateTime	" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.CreateTime | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单过期时间" width="160" prop="OrderExpiredTime	" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.OrderExpiredTime | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          align="center"
          label="操作"
          width="180">
        <template slot-scope="scope">
          <el-button v-if="!scope.row.IsCancel && scope.row.OrderStatu === 0" type="text" size="small" @click="outBound(scope.row.Id)">库存绑定</el-button>
          <el-button v-if="!scope.row.IsCancel && scope.row.OrderStatu === 0"  class="mr10" type="text" size="small" @click="bookToBill(scope.row)">申请发货</el-button>
          <el-dropdown v-if="!scope.row.IsCancel"  trigger="click" @command="handleCommand($event, scope.row)">
            <span class="el-dropdown-link">
              更多<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="order" style="font-size: 12px" icon="el-icon-document">拣货单</el-dropdown-item>
              <el-dropdown-item style="font-size: 12px" icon="el-icon-edit-outline">修改收货地址</el-dropdown-item>
              <el-dropdown-item style="font-size: 12px" icon="el-icon-link">绑定快递单号</el-dropdown-item>
              <el-dropdown-item style="font-size: 12px" icon="el-icon-scissors">更改货物类型</el-dropdown-item>
              <el-dropdown-item style="font-size: 12px" command="detail" icon="el-icon-search">发货查看</el-dropdown-item>
              <el-dropdown-item command="unBind" style="font-size: 12px" icon="el-icon-circle-close">解绑库存</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        class="custom-pagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleChange"
        :current-page="PageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="PageRows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
    <shopee-drawer :initial-query="drawerQuery" @handle-confirm="handleConfirm" @close="handleDrawerClose" :drawer="drawer"></shopee-drawer>
    <shopee-outbound @close="handleVisibleClose" @refresh="handleRefresh" :order-id="currentStateId" :show="visible" :list-data="outBoundData"></shopee-outbound>
    <out-bound-detail @close="handleDetailClose" :order-id="currentStateId" :show="detailVisible" :list-data="outBoundData"></out-bound-detail>
    <stock-quantity-detail @close="handleStockQuantityClose" :item-id="currentItemId" :show="stockQuantityVisible"></stock-quantity-detail>
  </div>
</template>

<script>
import {getOrderItemDetail, getOrderList, getSheetByte, unBoundStock} from "@/api/member";
import printJS from "print-js";
import ShopeeDrawer from "@/views/member/shopee/components/shopeeDrawer.vue";
import ShopeeOutbound from "@/views/member/shopee/components/ShopeeOutbound.vue";
import outBoundDetail from "@/views/member/shopee/components/outBoundDetail.vue";
import StockQuantityDetail from "@/views/member/shopee/components/StockQuantityDetail.vue";
import {orderToDelivery} from "@/api/cod";
export default {
  components: { ShopeeDrawer, ShopeeOutbound, outBoundDetail, StockQuantityDetail },
  data() {
    return {
      queryCode: "",
      currentStateId: 0,
      currentItemId: 0,
      PageIndex: 1,
      PageRows: 10,
      total: 0,
      loading: false,
      detailVisible: false,
      stockQuantityVisible: false,
      drawer: false,
      visible: false,
      outBoundData: [],
      tableData: [],
      multipleSelection: [],
      querys: {},
      drawerQuery: {
        dateRange: []
      },
    }
  },
  created() {
    this.getData()
  },
  filters: {
    formatType(val) {
      if (val) {
        return "是"
      } else {
        return "否"
      }
    }
  },
  computed: {
    tableHeight() {
      return 'calc(100vh - 19.3rem - 96px)'
    },
    timestampName() {
      const timestamp = Date.now()
      return `虾皮订单-${timestamp}.xls`
    },
    fields() {
      const tableFields = {}
      tableFields['订单号'] = 'MainBillCode'
      tableFields['承运单号'] = 'CarrierBillCode'
      return tableFields
    },
    badgeNum() {
      let count = 0;
      for (let key in this.drawerQuery) {
        if (Array.isArray(this.drawerQuery[key])) {
          if (this.drawerQuery[key].length) {
            count++;
          }
        } else {
          if (this.drawerQuery[key] !== undefined && this.drawerQuery[key] !== '') {
            count++;
          }
        }
      }
      return count;
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleStockQuantityClose() {
      this.stockQuantityVisible = false
    },
    showStockQuantityDetail(ItemId) {
      this.currentItemId = ItemId
      this.stockQuantityVisible = true
    },
    bookToBill(row) {
      this.$confirm(`是否对订单编号为（${row.MainBillCode}）的订单出货，是否继续？`,
          "提示",
          {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: 'warning'
          }
      ).then(() => {
        const data = {
          OrderId: row.Id
        }
        orderToDelivery(data).then(response => {
          const { Code, Msg } = response
          if (Code === 200) {
            this.$message.success("发货成功")
            this.getData()
          } else {
            this.$message.warning(Msg)
          }
        })
      })
    },
    handleDrawerClose() {
      this.drawer = false
    },
    getClasses(state) {
      if (state === 4) {
        return "info-ball"
      } else if (state === 1) {
        return "success-ball"
      } else if (state === 2) {
        return "warning-ball"
      } else {
        return "half-warning-ball"
      }
    },
    showFilter() {
      this.drawer = true
    },
    handleConfirm(query) {
      this.drawerQuery = query
      this.handleDrawerClose()
      this.PageIndex = 1;
      this.getData()
    },
    handleCommand(event, row) {
      if (event === 'unBind') {
        this.unBindSku(row)
      } else if (event === 'order') {
        this.byteToPdf(row.MainBillCode)
      } else if (event === 'detail') {
        this.showOutBoundDetail(row.Id)
      }
    },
    unBindSku(row) {
      this.$confirm(`该操作会解除编号为（${row.MainBillCode}）的SKU库存是否继续？`,
          "提示",
          {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: 'warning'
          }
      ).then(() => {
        const data = {
          Id: row.Id
        }
        unBoundStock(data).then(response => {
          const { Code, Msg } = response
          if (Code === 200) {
            this.$message.success("解除绑定成功")
            this.getData()
          } else {
            this.$message.warning(Msg)
          }
        })
      })
    },
    orderStatusFormatter(row, column, cellValue) {
      if (cellValue === 0) {
        return "不限"
      } else if (cellValue === 1) {
        return "待发货"
      } else {
        return "已发货"
      }
    },
    handleChange(val) {
      this.PageIndex = val
      this.getData()
    },
    clearQuery() {
      this.PageIndex = 1
      this.getData()
    },
    resetForm() {
      this.querys = {
      }
      this.drawerQuery = {
        dateRange: []
      }
      this.clearQuery()
    },
    showOutBoundDetail(OrderId) {
      const data = {
        PageIndex: 1,
        PageRows: 10,
        OrderId: OrderId
      }
      getOrderItemDetail(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.outBoundData = Data.map(item => {
            return {
              ...item,
              SkuDetail: {}
            }
          })
          this.currentStateId = OrderId
          this.detailVisible = true
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    outBound(OrderId) {
      const data = {
        PageIndex: 1,
        PageRows: 10,
        OrderId: OrderId
      }
      getOrderItemDetail(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.outBoundData = Data.map(item => {
            return {
              ...item,
              SkuDetail: {},
              outBoundAmount: item.Amount || 0
            }
          })
          this.currentStateId = OrderId
          this.visible = true
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    handleVisibleClose() {
      this.getData()
      this.visible = false
    },
    handleDetailClose() {
      this.detailVisible = false
    },
    handleSizeChange(val) {
      this.PageRows = val
      this.PageIndex = 1;
      this.getData()
    },
    byteToPdf(orderId) {
      const data = {
        BillCodes: [orderId],
        TemplateName: "虾皮拣货单"
      }
      getSheetByte(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          // const pdfurl = `data:application/pdf;base64,${Data[0]}`
          printJS({
            printable: Data[0].File,
            type: 'pdf',
            base64: true
          })
        } else {
          this.$message.warning(Msg)
        }

      })
    },
    handleRefresh() {
      this.handleVisibleClose()
      this.getData()
    },
    getData() {
      let QueryCodes = this.querys.code ? this.querys.code.split(/[，,\s\r\n\t]+/) : undefined
      const data = {
        PageIndex: this.PageIndex,
        PageRows: this.PageRows,
        Codes: [],
        Type: this.orderType,
        QueryCodeType: 3,
        QueryCodes: QueryCodes,
        CarrierId: this.drawerQuery.carrier || undefined,
        WareHouseIds: this.drawerQuery.warehouse ? [this.drawerQuery.warehouse] : undefined,
        DestinationId: this.drawerQuery.destination || undefined,
        StartTime: this.drawerQuery.dateRange && this.drawerQuery.dateRange.length ? this.drawerQuery.dateRange[0] : undefined,
        EndTime: this.drawerQuery.dateRange && this.drawerQuery.dateRange.length ? this.drawerQuery.dateRange[1] : undefined,
        QueryType: 1,
        OrderSource: 4,
        ElectronicShopId: this.drawerQuery.ElectronicShopId || undefined,
        Rem: this.querys.rem,
        IsCancel: this.drawerQuery.orderType || undefined,
        StockState: this.drawerQuery.StockState || undefined,
        OrderStatu: this.drawerQuery.OrderStatu || undefined
      }
      this.loading = true
      getOrderList(data).then(response => {
        this.loading = false
        const { Code, Msg, Data, Total } = response
        if (Code === 200) {
          this.total = Total
          this.tableData = Data
          this.$nextTick(() => {
            this.$refs.multipleTable.bodyWrapper.scrollTop = 0
          })
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    tableRowClassName({ row }) {
      return row.IsCancel ? 'canceled-row' : '';
    },
  }
}

</script>

<style scoped lang="scss">
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
  font-size: 12px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.default-font {
  width: 60px;
}
.warning-ball {
  width: 12px;
  height: 12px;
  min-height: 12px;
  min-width: 12px;
  border-radius: 50%;
  background: #ef0606;
}

.half-warning-ball {
  width: 12px;
  height: 12px;
  min-height: 12px;
  min-width: 12px;
  border-radius: 50%;
  background: linear-gradient(to right, #08c421 50%, #ef0606 50%);
}

.success-ball {
  width: 12px;
  height: 12px;
  min-height: 12px;
  min-width: 12px;
  border-radius: 50%;
  background: #08c421;
}

.info-ball {
  width: 12px;
  height: 12px;
  min-height: 12px;
  min-width: 12px;
  border-radius: 50%;
  background: #727070;
}
::v-deep .success-row {
  background: #f0f9eb;
}
::v-deep .header-row {
  background: oldlace !important;
}
::v-deep .el-table__cell {
  padding: 0.8rem 0;
}
::v-deep .el-table .cell {
  font-size: 1.4rem;
}
.shopee-img {
  width: 32px;
  height: 32px;
  border-radius: 5px;
}
.order-container {
  padding: 0.5rem 1rem;
  background: #f5f7fa;
  height: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  
  // 搜索区域样式优化
  .search-section {
    flex-shrink: 0;
    background: white;
    padding: 1.2rem 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
    margin-bottom: 1rem;

    .search-content {
      display: flex;
      align-items: center;
      gap: 0.8rem;

      .search-input {
        width: 20rem;
        
        ::v-deep .el-input__inner {
          height: 28px;
          line-height: 28px;
          border-radius: 4px;
          
          &:hover {
            border-color: #409EFF;
          }
        }

        ::v-deep .el-input__prefix {
          left: 8px;
          height: 28px;
          line-height: 28px;
          color: #909399;
        }

        ::v-deep .el-input__inner {
          padding-left: 30px;
        }
      }

      .filter-badge {
        margin: 0;
      }

      .export-excel-wrapper {
        margin: 0;
      }
    }
  }

  // 状态指示器样式优化
  .status-indicators {
    flex-shrink: 0;
    background: white;
    padding: 1rem 1.5rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    display: flex;
    gap: 2rem;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);

    .status-item {
      display: flex;
      align-items: center;
      gap: 0.6rem;
      font-size: 1.3rem;
      height: 1.3rem;
      color: #606266;

      .status-dot {
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
      }
    }
  }

  // 表格容器样式优化
  .custom-table {
    flex: 1;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
    
    ::v-deep {
      .el-table__body-wrapper {
        overflow-y: auto;
        
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
        
        &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background-color: #dcdfe6;
          
          &:hover {
            background-color: #c0c4cc;
          }
        }
        
        &::-webkit-scrollbar-track {
          border-radius: 3px;
          background-color: #f5f7fa;
        }
      }

      .el-table__body-wrapper--horizontal-scroll {
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
        
        &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background-color: #dcdfe6;
          
          &:hover {
            background-color: #c0c4cc;
          }
        }
        
        &::-webkit-scrollbar-track {
          border-radius: 3px;
          background-color: #f5f7fa;
        }
      }

      .el-table__header-wrapper {
        th {
          background: #f5f7fa;
          color: #606266;
          font-weight: 600;
        }
      }

      .el-table__row {
        &:hover > td {
          background: #f5f7fa !important;
        }
      }

      .el-table__expand-icon {
        color: #409EFF;
      }
    }
  }

  // 分页器样式优化
  .custom-pagination {
    flex-shrink: 0;
    margin-top: 1rem;
    padding: 0.8rem 1.5rem;
    background: white;
    border-radius: 8px;
    text-align: right;

    ::v-deep {
      .el-pagination__total {
        font-size: 1.3rem;
      }

      .el-pagination__sizes {
        margin-left: 1rem;
      }

      .el-pager li {
        border-radius: 4px;
        
        &.active {
          background-color: #409EFF;
        }
      }
    }
  }

  // 状态球样式优化
  .warning-ball {
    background: #f56c6c;
  }

  .half-warning-ball {
    background: linear-gradient(to right, #67c23a 50%, #f56c6c 50%);
  }

  .success-ball {
    background: #67c23a;
  }

  .info-ball {
    background: #909399;
  }

  // 图片样式优化
  .shopee-img {
    width: 4rem;
    height: 4rem;
    border-radius: 4px;
    object-fit: cover;
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.1);
    }
  }
}

// 全局滚动条样式（如果需要的话）
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #dcdfe6;
  
  &:hover {
    background-color: #c0c4cc;
  }
}

::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #f5f7fa;
}

::v-deep .canceled-row {
  // 调整为更深的灰色
  background-color: #f0f0f0 !important;
  color: #909399 !important; // 添加文字颜色
  
  // 让鼠标悬停时保持相同的背景色
  &:hover > td {
    background-color: #f0f0f0 !important;
  }
  
  // 设置展开行的背景色
  .el-table__expand-column {
    .el-table__expand-inner {
      background-color: #f0f0f0;
    }
  }
  
  // 设置展开内容的背景色
  .el-table__expanded-cell {
    background-color: #f0f0f0 !important;
    
    // 设置展开内容中的表格背景色
    .el-table {
      background-color: #f0f0f0;
      
      // 设置表头背景和文字颜色
      th {
        background-color: #e8e8e8 !important;
        color: #909399 !important;
      }
      
      // 设置展开内容中表格的行背景色
      tr {
        background-color: #f0f0f0 !important;
        color: #909399 !important;
        
        &.success-row {
          background-color: #f0f0f0 !important;
        }
      }
      
      // 设置展开内容中表格的单元格背景色
      td {
        background-color: #f0f0f0 !important;
        color: #909399 !important;
      }
    }
  }
  
  // 确保所有文字都变灰
  td {
    color: #909399 !important;
  }
}

// 确保展开行的样式正确应用
::v-deep .el-table__expanded-cell {
  padding: 20px !important;
}

::v-deep .canceled-detail-row {
  background-color: #f0f0f0 !important;
  color: #909399 !important;
  
  &:hover > td {
    background-color: #f0f0f0 !important;
  }
  
  td {
    background-color: #f0f0f0 !important;
    color: #909399 !important;
  }
}

::v-deep .canceled-header-row {
  background-color: #e8e8e8 !important;
  color: #909399 !important;
  
  th {
    background-color: #e8e8e8 !important;
    color: #909399 !important;
  }
}

.stock-link {
  color: #409EFF;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  
  &:hover {
    color: #66b1ff;
    text-decoration: underline;
  }
  
  .el-icon-view {
    font-size: 14px;
  }
}
</style>
