<template>
  <div class="user-info">
    <div class="u-between-flex" style="padding-right: 5rem">
      <div class="user-headers u-start-flex">
        <div class="round-image">
          <el-avatar :size="60" :src="userInfo.HostPortrait"></el-avatar>
        </div>
        <div>
          <div style="margin-bottom: 1rem" class="medium-font">{{ $t('personal.username') }}：{{ userInfo.Name }}</div>
          <div class="default-font">{{ $t('personal.accountnumber') }}：{{ userInfo.UserName }}&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('personal.membernumber') }}：{{ userInfo.Id }}</div>
        </div>
      </div>
      <div class="u-start-flex">
        <div class="u-start-flex sign-btn pointer mr10" @click="showSign=true">
          <el-image :src="require('@/static/images/member/personal/sign.png')" class="sign-icon"></el-image>
          <div>{{ $t('personal.sign') }}</div>
        </div>
        <div class="u-start-flex recharge-btn pointer mr10" @click="show=true">
          <el-image :src="require('@/static/images/member/personal/money.png')" class="recharge-icon"></el-image>
          <div>{{ $t('personal.recharge') }}</div>
        </div>
        <div class="u-start-flex withdrawal-btn pointer" @click="showWithdrawal=true">
          <el-image :src="require('@/static/images/member/personal/withdrawal.png')" class="withdrawal-icon"></el-image>
          <div>{{ $t('personal.withdrawalMoneyTitle') }}</div>
        </div>
      </div>
    </div>
    <div class="user-business u-between-flex" @click="toPage('Recommend')">
      <div class="u-start-flex pointer">
        <el-image :src="require('@/static/images/member/personal/location.png')" class="member-img"></el-image>
        <div>
          <div class="mb8">{{ $t('personal.recommend') }}</div>
          <div style="color: #FF9C3A">{{ $t('personal.commission') }}</div>
        </div>
      </div>
      <div class="u-start-flex pointer" @click="toPage('Agent')">
        <el-image :src="require('@/static/images/member/personal/coupon.png')" class="member-img"></el-image>
        <div>
          <div class="mb8">{{ userInfo.WithdrawalMoney }}</div>
          <div style="color: #3889FF">{{ $t('personal.withdrawal') }}</div>
        </div>
      </div>
      <div class="grey-line"></div>
      <div class="u-start-flex pointer" @click="toPage('flow')">
        <el-image :src="require('@/static/images/member/personal/amount.png')" class="member-img"></el-image>
        <div>
          <div style="font-size: 1.6rem; color: #5DB646; text-align: center">{{ userInfo.Money }}</div>
          <div>{{ $t('personal.amount') }}</div>
        </div>
      </div>
      <div class="u-start-flex pointer" @click="toPage('coupon')">
        <el-image :src="require('@/static/images/member/personal/integral.png')" class="member-img"></el-image>
        <div>
          <div style="font-size: 1.6rem; color: #4BA7F9">{{ Integral }}</div>
          <div>{{ $t('personal.integral') }}</div>
        </div>
      </div>
      <div class="u-start-flex pointer" @click="toPage('coupon', { name: 'coupon' })">
        <el-image :src="require('@/static/images/member/personal/roll.png')" class="member-img"></el-image>
        <div>
          <div style="font-size: 1.6rem; color: #F8AE00; text-align: center">{{ CouponCount }}</div>
          <div>{{ $t('personal.coupon') }}</div>
        </div>
      </div>
    </div>
    <div class="edit-user pointer" @click="$router.push({ path: '/member/account' })">
      <i class="el-icon-edit"></i>
    </div>
    <withdrawal-box :show.sync="showWithdrawal" @close="showWithdrawal=false"></withdrawal-box>
    <sign-box :show.sync="showSign" @close="showSign=false"></sign-box>
    <recharge-box :show.sync="show" @close="closeVisible" @handle="handleRecharge"></recharge-box>
    <cashier :recharge-number="rechargeNumber" :show="cashierShow" :title="$t('personal.balance')" orderType="recharge" @close="handleClose"></cashier>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import RechargeBox from "@/components/RechargeBox.vue";
import Cashier from "@/components/cashier.vue";
import SignBox from "@/components/SignBox.vue";
import WithdrawalBox from "@/components/WithdrawalBox.vue";
export default {
  data() {
    return {
      show: false,
      cashierShow: false,
      rechargeNumber: undefined,
      showWithdrawal: false,
      showSign: false
    }
  },
  computed: {
    ...mapGetters(["userInfo", "Integral", "CouponCount"])
  },
  components: {
    RechargeBox,
    Cashier,
    SignBox,
    WithdrawalBox
  },
  methods: {
    closeVisible() {
      this.show = false
    },
    handleClose() {
      this.cashierShow = false;
      this.$store.dispatch('user/getInfo')
    },
    handleRecharge(number) {
      this.rechargeNumber = number
      this.show = false;
      this.cashierShow = true
    },
    toPage(name, query = {}) {
      this.$router.push({
        name: name,
        query: query
      })
    }
  }
}

</script>

<style scoped lang="scss">
.user-info {
  position: relative;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin: 12px;
  transition: all 0.3s ease;

  // 用户头像区域样式优化
  .user-headers {
    padding: 16px 20px;
    border-bottom: 1px solid #f0f2f5;

    .round-image {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-right: 16px;
      overflow: hidden;
      border: 2px solid #fff;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.05);
      }
    }

    // 用户名和账号信息
    .medium-font {
      font-size: 15px;
      margin-bottom: 8px !important;
    }
    .default-font {
      font-size: 13px;
      color: #666;
    }
  }

  // 按钮样式统一优化
  .sign-btn,
  .recharge-btn,
  .withdrawal-btn {
    padding: 8px 16px;
    border-radius: 6px;
    font-size: 13px;
    font-weight: 500;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin-right: 8px;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    }

    .sign-icon,
    .recharge-icon,
    .withdrawal-icon {
      width: 16px;
      margin-right: 6px;
    }
  }

  .sign-btn {
    background: linear-gradient(135deg, #ff4b4b, #bb2232);
  }

  .recharge-btn {
    background: linear-gradient(135deg, #4cd964, #2EE424);
  }

  .withdrawal-btn {
    background: linear-gradient(135deg, #ffb340, #dec20a);
  }

  // 用户数据展示区域优化
  .user-business {
    padding: 16px 20px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 12px;
    
    > div {
      padding: 14px 12px;
      border-radius: 8px;
      transition: all 0.3s ease;
      background: #f8f9fa;
      min-width: 90px;
      
      &:hover {
        background: #fff;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
        transform: translateY(-2px);
      }

      // 统一图标样式
      .member-img {
        width: 24px;
        height: 24px;
        margin-right: 6px;
        object-fit: contain;
      }

      // 数据展示区块内部样式
      > div:last-child {
        flex: 1;
        
        // 数值显示
        div:first-child {
          font-size: 16px !important;
          font-weight: 600;
          line-height: 1.4;
          margin-bottom: 4px;
          text-align: center;

          // 不同类型数值的颜色
          &[style*="color: #5DB646"] { color: #5DB646 !important; }
          &[style*="color: #4BA7F9"] { color: #4BA7F9 !important; }
          &[style*="color: #F8AE00"] { color: #F8AE00 !important; }
        }

        // 标签文字
        div:last-child {
          font-size: 13px;
          color: #666;
          text-align: center;
          white-space: nowrap;
        }
      }

      // 特殊样式：推荐/佣金
      &:first-child {
        div:last-child {
          div:first-child {
            font-size: 13px;
            color: #333;
            margin-bottom: 4px;
          }
          div:last-child {
            color: #FF9C3A;
            font-weight: 500;
          }
        }
      }
    }

    .grey-line {
      display: none;
    }
  }

  // 编辑按钮样式优化
  .edit-user {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: rgba(245, 247, 250, 0.8);
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.05);

    &:hover {
      background: #e4e7ed;
      transform: rotate(90deg);
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }

    i {
      font-size: 16px;
      color: #409EFF;
    }
  }
}

// 响应式优化
@media screen and (max-width: 768px) {
  .user-info {
    margin: 8px;

    .user-business {
      grid-template-columns: repeat(2, 1fr);
      gap: 12px;
      padding: 12px;

      > div {
        padding: 12px 10px;

        .member-img {
          width: 20px;
          height: 20px;
        }

        > div:last-child {
          div:first-child {
            font-size: 14px !important;
          }
          div:last-child {
            font-size: 12px;
          }
        }
      }
    }

    .edit-user {
      width: 30px;
      height: 30px;
      top: 12px;
      right: 12px;

      i {
        font-size: 14px;
      }
    }
  }
}
</style>
