<template>
  <div class="flow-container">
    <div class="statistics-cards">
      <div class="stat-card">
        <div class="stat-content">
          <div class="stat-label">{{ $t('flow.title') }}</div>
          <el-statistic
            group-separator=","
            :value-style="{color: '#6366F1', fontSize: '2.2rem', fontWeight: '600'}"
            :precision="2"
            :value="statistics.ConsumptionTotalAmount"
          ></el-statistic>
        </div>
        <div class="stat-icon purple">
          <el-image :src="require('@/static/images/member/flow/statistics.png')"></el-image>
        </div>
      </div>

      <div class="stat-card">
        <div class="stat-content">
          <div class="stat-label">{{ $t('flow.residue') }}</div>
          <el-statistic
            group-separator=","
            :value-style="{color: '#EC4899', fontSize: '2.2rem', fontWeight: '600'}"
            :precision="2"
            :value="statistics.SurplusAvailable"
          ></el-statistic>
        </div>
        <div class="stat-icon pink">
          <el-image :src="require('@/static/images/member/flow/money.png')"></el-image>
        </div>
      </div>

      <div class="stat-card">
        <div class="stat-content">
          <div class="stat-label">{{ $t('flow.thisWeek') }}</div>
          <el-statistic
            group-separator=","
            :value-style="{color: '#0EA5E9', fontSize: '2.2rem', fontWeight: '600'}"
            :precision="2"
            :value="statistics.CurrentConsumption"
          ></el-statistic>
        </div>
        <div class="stat-icon blue">
          <el-image :src="require('@/static/images/member/flow/forms.png')"></el-image>
        </div>
      </div>

      <div class="stat-card">
        <div class="stat-content">
          <div class="stat-label">{{ $t('flow.today') }}</div>
          <el-statistic
            group-separator=","
            :value-style="{color: '#10B981', fontSize: '2.2rem', fontWeight: '600'}"
            :precision="2"
            :value="statistics.ConsumptionToday"
          ></el-statistic>
        </div>
        <div class="stat-icon green">
          <el-image :src="require('@/static/images/member/flow/count.png')"></el-image>
        </div>
      </div>
    </div>

    <div class="search-section">
      <div class="search-form">
        <el-select 
          size="small" 
          v-model="query.WalletLogType" 
          :placeholder="$t('flow.placeholderWalletLogType')"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        
        <el-input 
          size="small" 
          v-model="query.TransactionNo" 
          @clear="getData" 
          clearable 
          :placeholder="$t('flow.placeholderTransactionNo')"
        ></el-input>
        
        <div class="button-group">
          <el-button size="small" type="primary" @click="getData">
            <i class="el-icon-search mr-5"></i>
            {{ $t('flow.search') }}
          </el-button>
          
          <download-excel
            class="export-excel-wrapper"
            :data="flows"
            :fields="fields"
            :name="timestampName"
            worksheet="sheet1"
          >
            <el-button size="small" type="success">
              <i class="el-icon-download mr-5"></i>
              导出
            </el-button>
          </download-excel>
        </div>
      </div>
    </div>

    <el-table
        :data="flows"
        style="width: 100%"
        v-loading="loading"
        size="mini"
        border>
      <el-table-column prop="OrderNo" :label="$t('flow.OrderNo')" width="200"></el-table-column>
      <el-table-column prop="TransactionNo" :label="$t('flow.TransactionNo')" width="240"></el-table-column>
      <el-table-column prop="Rem" show-overflow-tooltip :label="$t('flow.Rem')" width="200"></el-table-column>
      <el-table-column prop="TypeName" :label="$t('flow.TypeName')"></el-table-column>
      <el-table-column prop="CreateTime" :label="$t('flow.CreateTime')" width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.CreateTime | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="VariableState" :label="$t('flow.VariableState')"></el-table-column>
      <el-table-column prop="Money" :label="$t('flow.Money')"></el-table-column>
      <el-table-column prop="OldMoney" :label="$t('flow.OldMoney')"></el-table-column>
    </el-table>
    <el-pagination
        class="mt20"
        background
        @size-change="handleSizeChange"
        @current-change="handleChange"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import {getWalletFlow} from "@/api/system";
export default {
  name: "index",
  data() {
    return {
      query: {
        TransactionNo: undefined,
        WalletLogType: 1
      },
      flows: [],
      statistics: {},
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    tableHeight() {
      return 'calc(100vh - 12rem - 154px)'
    },
    fields() {
      const tableFields = {}
      tableFields['系统单号'] = 'OrderNo'
      tableFields['交易流水号'] = 'TransactionNo'
      tableFields['备注说明'] = 'Rem'
      tableFields['交易类型'] = 'TypeName'
      tableFields['交易时间'] = 'CreateTime'
      tableFields['状态'] = 'VariableState'
      tableFields['变动后金额'] = 'NewMoney'
      tableFields['变动金额'] = 'Money'
      tableFields['变动前金额'] = 'OldMoney'
      return tableFields
    },
    timestampName() {
      const timestamp = Date.now()
      return `钱包流水${timestamp}.xls`
    },
    options() {
      return  [
        {
          label: this.$t('flow.Balance'),
          value: 1
        },
        {
          label: this.$t('flow.Integral'),
          value: 2
        }
      ]
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val
      this.pageIndex = 1;
      this.getData()
    },
    handleChange(current) {
      this.pageIndex = current;
      this.getData()
    },
    getData() {
      const data = {
        PageIndex: this.pageIndex,
        PageRows: this.pageSize,
        ClientId: this.userInfo.Id,
        Type: this.query.WalletLogType || undefined,
        TransactionNo: this.query.TransactionNo
      }
      this.loading = true
      getWalletFlow(data).then(response => {
        this.loading = false
        const { Code, Msg, Data, Total } = response
        if (Code === 200) {
          const { ClientWalletLogInfo, ConsumptionToday, ConsumptionTotalAmount, CurrentConsumption, SurplusAvailable } = Data
          this.statistics = {
            ConsumptionToday,
            ConsumptionTotalAmount,
            CurrentConsumption,
            SurplusAvailable
          }
          this.flows = ClientWalletLogInfo;
          this.total = Total
        } else {
          this.$message.warning(Msg)
        }
      })
    }
  },
  created() {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.flow-container {
  padding: 2rem 2.5rem;
  background-color: #f9fafb;

  .statistics-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    margin-bottom: 2rem;

    .stat-card {
      background: white;
      border-radius: 1rem;
      padding: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      transition: transform 0.2s, box-shadow 0.2s;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      }

      .stat-content {
        .stat-label {
          color: #6b7280;
          font-size: 1.4rem;
          margin-bottom: 0.8rem;
        }
      }

      .stat-icon {
        width: 4.8rem;
        height: 4.8rem;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .el-image {
          width: 2.4rem;
          height: 2.4rem;
        }

        &.purple { background-color: #EEF2FF; }
        &.pink { background-color: #FDF2F8; }
        &.blue { background-color: #F0F9FF; }
        &.green { background-color: #ECFDF5; }
      }
    }
  }

  .search-section {
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    margin-bottom: 2rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    .search-form {
      display: flex;
      gap: 1.5rem;
      align-items: center;

      .el-select,
      .el-input {
        width: 24rem;
      }

      .button-group {
        display: flex;
        gap: 1rem;
      }
    }
  }
}

.mr-5 {
  margin-right: 0.5rem;
}

::v-deep .el-table {
  border-radius: 1rem;
  overflow: hidden;
}
</style>
