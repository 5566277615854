<template>
  <el-dialog top="5vh" :close-on-click-modal="false" :append-to-body="body" :before-close="close" :title="textMap[dialogStatus]" width="35%" :visible.sync="show">
    <el-form :model="temp" :rules="rules" size="small" ref="ruleForm" label-position="left" label-width="100px" v-loading="loadingForm">
      <el-form-item :label="$t('address.serviceType')" prop="Type">
        <el-select @change="handleTypeChange" v-model="temp.Type" :placeholder="$t('address.serviceType')" style="width: 48rem">
          <el-option v-for="item in serviceTypeOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('address.destination')" prop="DestinationId">
        <el-select @change="handleChange($event, 'destination')" v-model="temp.DestinationId" :placeholder="$t('address.selectdestination')" style="width: 48rem">
          <el-option v-for="item in options.destination" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <div class="u-between-flex" style="width: 48rem">
        <el-form-item :label="$t('address.areas')" prop="ProvinceId">
          <el-select filterable :disabled="!options.province.length" @change="handleChange($event, 'province')" v-model="temp.ProvinceId" :placeholder="$t('address.selectprovince')" :style="{width: temp.Type === 2 ? '12rem' : '16rem'}">
            <el-option v-for="item in options.province" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="0" prop="CityId">
          <el-select filterable :disabled="!options.city.length" @change="handleChange($event, 'city')" v-model="temp.CityId" :placeholder="$t('address.selectcity')" :style="{width: temp.Type === 2 ? '12rem' : '16rem'}">
            <el-option v-for="item in options.city" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="0" prop="AreaId">
          <el-select filterable :disabled="!options.area.length" @change="handleChange($event, 'area')" v-model="temp.AreaId" :placeholder="$t('address.selectarea')" :style="{width: temp.Type === 2 ? '12rem' : '16rem'}">
            <el-option v-for="item in options.area" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="temp.Type === 2" label-width="0" prop="AgentPointId">
          <el-select :disabled="!options.agent.length" @change="handleChange($event, 'agent')" v-model="temp.AgentPointId" :placeholder="$t('address.selectagent')" :style="{width: temp.Type === 2 ? '12rem' : '16rem'}">
            <el-option v-for="item in options.agent" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <el-form-item :label="$t('address.recipientaddress')" prop="RecipientAddress">
        <el-input v-model="temp.RecipientAddress" :disabled="temp.Type === 2" size="small" autocomplete="off" style="width: 48rem"></el-input>
      </el-form-item>
      <el-form-item :label="$t('address.recipientname')" prop="RecipientName">
        <el-input v-model="temp.RecipientName" size="small" autocomplete="off" style="width: 48rem"></el-input>
      </el-form-item>
      <el-form-item :label="$t('address.recipientphone')" prop="RecipientPhone">
        <el-input v-model="temp.RecipientPhone" size="small" autocomplete="off" style="width: 48rem"></el-input>
      </el-form-item>
      <el-form-item :label="$t('address.IsDefaultAddress')" prop="IsDefaultAddress">
        <el-switch
            v-model="temp.IsDefaultAddress">
        </el-switch>
      </el-form-item>
      <div style="width: 100%; text-align: center" v-if="!expand">
        <el-button @click="expand=true" size="small" type="text">
          {{ $t('address.pasteAddress') }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
      </div>
      <el-form-item :label="$t('address.identifyAddress')" prop="pasteAddress" v-if="expand">
        <div class="u-start-flex" style="align-items: flex-start">
          <el-input class="mr10" autosize :placeholder="$t('address.placeholderPasteAddress')" v-model="pasteAddress" type="textarea"></el-input>
          <el-button v-if="pasteAddress" @click="identifyAddress" type="primary" size="mini">{{ $t('address.identify') }}</el-button>
        </div>
      </el-form-item>
      <div style="width: 100%; text-align: center" v-if="expand">
        <el-button @click="expand=false" size="small" type="text">
          {{ $t('address.pasteAddress') }}
          <i class="el-icon-arrow-up el-icon--right"></i>
        </el-button>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: right">
      <el-button v-loading="loading" type="primary" @click="submitForm('ruleForm')">{{ dialogStatus==='update'?$t('address.confirmupdate'):$t('address.Confirmaddition') }}</el-button>
      <el-button @click="close">{{ $t('address.cancel') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getArea, getCity, getCountry, getProvince } from "@/api/system";
import {createAddress, getAgent, recognizeAddress, updateAddress} from "@/api/member";
import { mapGetters } from "vuex"
export default {
  name: "AddressOperation",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    temp: {
      type: Object,
      default: () => {
      }
    },
    dialogStatus: {
      type: String,
      default: 'create'
    },
    body: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loadingForm: false,
      expand: false,
      pasteAddress: "",
      loading: false,
      options: {
        destination: [],
        province: [],
        city: [],
        area: [],
        agent: []
      }
    }
  },
  computed: {
    ...mapGetters(['TenantId', 'userInfo']),
    textMap() {
      return {
        update: this.$t('address.editAddress'),
        create: this.$t('address.addAddress')
      }
    },
    serviceTypeOptions() {
      return [
        {
          label: this.$t('address.Dispatch'),
          value: 1
        },
        {
          label: this.$t('address.SelfPickup'),
          value: 2
        }
      ]
    },
    formData() {
      return {
        DestinationId: this.temp.DestinationId,
        AgentPointId: this.temp.Type === 2 ? this.temp.AgentPointId : undefined,
        Id: this.temp.Id,
        ProvinceId: this.temp.ProvinceId || undefined,
        Type: this.temp.Type,
        CityId: this.temp.CityId || undefined,
        AreaId: this.temp.AreaId || undefined,
        RecipientName: this.temp.RecipientName,
        RecipientPhone: this.temp.RecipientPhone,
        RecipientAddress: this.temp.RecipientAddress,
        ClientId: this.userInfo.Id,
        IsDefaultAddress: this.temp.IsDefaultAddress,
        IsNotFillProvinces: !this.temp.ProvinceId
      }
    },
    rules() {
      return {
        DestinationId: [
          { required: true, message: this.$t('address.selectCountry'), trigger: 'blur' },
        ],
        // AgentPointId: [
        //   { required: true, message: this.$t('address.selectAgentPoint'), trigger: 'blur' },
        // ],
        // ProvinceId: [
        //   { required: true, message: this.$t('address.selectProvince'), trigger: 'blur' },
        // ],
        Type: [
          { required: true, message: this.$t('address.selectServiceType'), trigger: 'blur' },
        ],
        // CityId: [
        //   { required: true, message: this.$t('address.selectCity'), trigger: 'blur' },
        // ],
        // AreaId: [
        //   { required: true, message: this.$t('address.selectDistrict'), trigger: 'blur' },
        // ],
        // AgentId: [
        //   {validator: this.validateAgent, trigger: 'blur'}
        // ],
        RecipientName: [
          { required: true, message: this.$t('address.enterRecipientInfo'), trigger: 'blur' },
        ],
        RecipientPhone: [
          { required: true, message: this.$t('address.enterContactPhone'), trigger: 'blur' },
        ],
        RecipientAddress: [
          { required: true, message: this.$t('address.enterDetailedAddress'), trigger: 'blur' },
        ],
      }
    },
  },
  watch: {
    show(newVal) {
      if (newVal && this.dialogStatus === 'create') {
        this.getCountry(false)
      }
    }
  },
  // mounted() {
  //   this.getCountry()
  // },
  methods: {
    forceFlush() {
      this.temp = Object.assign({}, this.temp)
    },
    identifyAddress() {
      const data = {
        AddressInfo: this.pasteAddress
      }
      this.loading = true;
      recognizeAddress(data).then(async response => {
        const {Code, Data} = response
        if (Code === 200) {
          this.temp = Object.assign({}, this.temp, Data)
          await this.getCountry(false)
          await this.fetchAddress('destination', this.temp['DestinationId'])
          await this.fetchAddress('province', this.temp['ProvinceId'])
          await this.fetchAddress('city', this.temp['CityId'])
          this.loading = false
          this.pasteAddress = ""
        } else {
          this.loading = false
          this.$message.warning("抱歉，没有识别成功嗷！")
        }
      }).catch(() => {
        this.loading = false
      })
    },
    clearValidate() {
      this.$refs['ruleForm'].clearValidate()
    },
    handleTypeChange(val) {
      if (val === 2) {
        this.getCountry(true)
      } else {
        this.getCountry(false)
      }
      this.temp.DestinationId = undefined;
      this.handleChange(undefined, "destination")
    },
    close() {
      this.$emit('close')
    },
    validateAgent() {
      return !(this.temp.Type === 2 && !this.temp.AgentId);
    },
    getCountry(IsAgent=undefined) {
      this.loadingForm = true;
      const data = {
        tenantId: this.TenantId,
        IsAgent: IsAgent
      }
      getCountry(data).then(response => {
        this.loadingForm = false;
        const { Data } = response
        this.options.destination  = Data.map(item => {
          return {
            label: item.Name,
            value: item.Id
          }
        })
      })
    },
    handleChange(val, flag) {
      if (flag === 'agent') {
        for (let i = 0; i < this.options.agent.length; i++) {
          if (this.options.agent[i].value === val) {
            this.$set(this.temp, 'RecipientAddress', this.options.agent[i].address)
            this.$set(this.temp, 'AgentPointName', this.options.agent[i].name)
            this.$set(this.temp, 'RecipientPhone', this.options.agent[i].phone)
            break
          }
        }
        return;
      }
      this.loadingForm = true;
      this.fetchAddress(flag, val).then(() => {
        this.loadingForm = false
        if (flag === 'destination') {
          this.$set(this.temp, 'ProvinceId', '');
          this.$set(this.temp, 'CityId', '');
          this.$set(this.temp, 'AreaId', '');
          this.$set(this.temp, 'AgentPointId', '')
        } else if (flag === 'province') {
          this.$set(this.temp, 'CityId', '');
          this.$set(this.temp, 'AreaId', '');
          this.$set(this.temp, 'AgentPointId', '')
        } else if (flag === 'city') {
          if (this.temp.serviceType) this.$set(this.temp, 'AgentPointId', '')
          this.$set(this.temp, 'AreaId', '');
        } else if (flag === 'area') {
          this.$set(this.temp, 'AgentPointId', '')
        }
      }).catch(() => {
        this.loadingForm = false
      })
    },
    fetchAddress(flag, val) {
      if (!val) return;
      let fetchApi
      let field
      const data = {
        pageIndex: 1,
        pageRows: 1000
      }
      if (flag === 'destination') {
        fetchApi = getProvince
        data['DestinationId'] = val
        field = 'province'
      } else if (flag === 'province') {
        fetchApi = getCity
        data['ProvinceId'] = val
        field = 'city'
      } else if (flag === 'city') {
        fetchApi = getArea
        data['CityId'] = val
        field = 'area'
      } else if (flag === 'area' && this.temp.Type === 2) {
        fetchApi = getAgent
        data['AreaId'] = val
        field = 'agent'
      }
      return new Promise((resolve, reject) => {
        fetchApi(data).then(response => {
          const { Code, Data, Msg } = response
          if (Code === 200) {
            this.$set(this.options, field, Data.map(item => {
              return {
                label: item.Name, value: item.Id,
                address: item.Address || "",
                name: item.Name || "",
                phone: item.Phone
              }
            }))
          } else {
            reject(Msg)
          }
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.dialogStatus === 'create') {
            createAddress(this.formData).then(response => {
              this.loading = false;
              if (response.Success) {
                this.$message({
                  message: this.$t('address.addSuccess'),
                  type: 'success'
                });
                this.$emit('handle')
              } else {
                this.$message({
                  message: response.Msg,
                  type: 'warning'
                });
              }
            }).catch(() => { this.loading = false; });
          } else {
            updateAddress(this.formData).then(response => {
              this.loading = false;
              if (response.Success) {
                this.$message({
                  message: this.$t('address.editSuccess'),
                  type: 'success'
                });
                this.$emit('handle')
              } else {
                this.$message({
                  message: response.Msg,
                  type: 'warning'
                });
              }
            }).catch(() => { this.loading = false; });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">

</style>
