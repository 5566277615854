<template>
  <div class="news-container">
    <div class="news-header">
      <h1 class="news-title">{{ name }}</h1>
      <div class="news-meta">
        <i class="el-icon-time"></i>
        {{ $t('message.releaseTime') }}：{{ createTime | formatDate }}
      </div>
      <div class="divider"></div>
    </div>
    <div class="news-content" v-html="content"></div>
  </div>
</template>

<script>
import { getNoticeDetail } from "@/api/system";
import { mapGetters } from "vuex"
export default {
  data() {
    return {
      newsId: undefined,
      content: "",
      name: "",
      createTime: ""
    }
  },
  computed: {
    ...mapGetters(['shopID'])
  },
  created() {
    this.newsId = this.$route.query.id;
    this.getData()
  },
  methods: {
    getData() {
      const params = {
        Id: this.newsId
      }
      getNoticeDetail(params).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.content = Data.Content;
          this.name = Data.Name;
          this.createTime = Data.CreateTime;
        } else {
          this.$message.warning(Msg)
        }
      })
    },
  }
}

</script>

<style scoped lang="scss">
.news-container {
  min-height: calc(100vh - (10rem + 44rem));
  width: 90%;
  max-width: 120rem;
  margin: 4rem auto;
  padding: 4rem;
  box-shadow: 0 0.2rem 2rem rgba(0, 0, 0, 0.08);
  background: #fff;
  border-radius: 1rem;
  
  @media screen and (max-width: 768px) {
    width: 95%;
    padding: 2rem;
    margin: 2rem auto;
  }
}

.news-header {
  margin-bottom: 4rem;
}

.news-title {
  font-size: 3.6rem;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 2rem;
  line-height: 1.4;
  font-weight: 600;
}

.news-meta {
  text-align: center;
  font-size: 1.8rem;
  color: #666;
  margin-bottom: 2rem;
  
  i {
    margin-right: 0.5rem;
    color: #21C1B7;
  }
}

.divider {
  height: 0.2rem;
  background: linear-gradient(to right, transparent, #21C1B7, transparent);
  margin: 2rem auto;
  width: 80%;
}

.news-content {
  font-size: 2.4rem;
  line-height: 1.8;
  color: #333;
  
  ::v-deep {
    img {
      max-width: 100%;
      height: auto;
      margin: 2rem 0;
      border-radius: 0.8rem;
    }
    
    p {
      margin: 2rem 0;
    }
    
    h1, h2, h3, h4, h5, h6 {
      margin: 3rem 0 2rem;
      color: #2c3e50;
    }
    
    a {
      color: #21C1B7;
      text-decoration: none;
      
      &:hover {
        text-decoration: underline;
      }
    }
    
    blockquote {
      border-left: 0.4rem solid #21C1B7;
      padding: 1rem 2rem;
      background: #f8f9fa;
      margin: 2rem 0;
      color: #666;
    }
  }
}
</style>
