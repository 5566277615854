<template>
  <div class="pickup-point-card">
    <div class="card-header">
      <h3 class="point-name">{{ item.Name }}</h3>
      <el-tag 
        :type="item.AgentStateText === '营业中' ? 'success' : 'info'"
        size="small"
      >
        {{ item.AgentStateText }}
      </el-tag>
    </div>

    <div class="card-content">
      <div class="info-group">
        <i class="el-icon-location"></i>
        <span class="address">{{ fullAddress }}</span>
      </div>

      <div class="info-group contact-group">
        <i class="el-icon-phone"></i>
        <div class="contact-info">
          <span v-if="item.Phone">{{ item.Phone }}</span>
          <span v-if="item.Tel">{{ item.Tel }}</span>
        </div>
      </div>

      <div class="info-row">
        <div class="info-item">
          <span class="label">{{ $t('pickupPoint.ServiceCharge') }}:</span>
          <span class="value">{{ item.ServiceCharge }}</span>
        </div>
        <div class="info-item">
          <span class="label">{{ $t('pickupPoint.StorageLife') }}:</span>
          <span class="value">{{ item.StorageLife }}</span>
        </div>
      </div>

      <div class="info-row remark-row">
        <div class="info-item full-width">
          <span class="label">{{ $t('pickupPoint.Rem') }}:</span>
          <span class="value">{{ item.Rem || '-' }}</span>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <el-button 
        type="primary" 
        size="medium" 
        @click="addToAddress"
        icon="el-icon-plus"
      >
        {{ $t('pickupPoint.Add') }}
      </el-button>
    </div>

    <address-operation
      ref="operation"
      @handle="operationSuccess"
      @close="closeVisible"
      :body="true"
      :show="addressVisible"
      :dialog-status="dialogStatus"
      :temp.sync="temp">
    </address-operation>
  </div>
</template>

<script>
import AddressOperation from "@/components/AddressOperation.vue";
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      addressVisible: false,
      dialogStatus: "create",
      temp: {}
    }
  },
  components: {
    AddressOperation
  },
  computed: {
    fullAddress() {
      return `${this.item.DestinationName}${this.item.ProvinceName || ""}${this.item.CityName || ""}${this.item.AreaName || ""}${this.item.Address}`
    }
  },
  methods: {
    operationSuccess() {
      this.addressVisible = false
    },
    closeVisible() {
      this.addressVisible = false
    },
    async addToAddress() {
      this.temp = {...this.item}
      this.temp['Type'] = this.temp['AddressType']
      this.temp['AgentPointId'] = this.temp['Id']
      this.temp['RecipientAddress'] = this.temp['Address']
      this.temp['RecipientPhone'] = this.temp['Phone']
      this.temp['RecipientName'] = this.temp['Account']
      await this.$refs.operation.getCountry(true)
      await this.$refs.operation.fetchAddress('destination', this.temp['DestinationId'])
      await this.$refs.operation.fetchAddress('province', this.temp['ProvinceId'])
      await this.$refs.operation.fetchAddress('city', this.temp['CityId'])
      await this.$refs.operation.fetchAddress('area', this.temp['AreaId'])
      this.addressVisible = true
      this.$nextTick(() => {
        this.$refs.operation.clearValidate()
        this.$refs.operation.forceFlush()
      })
    }
  }
}

</script>

<style scoped lang="scss">
.pickup-point-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.05);
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  
  &:hover {
    box-shadow: 0 4px 12px 0 rgba(0,0,0,0.1);
  }

  .card-header {
    padding: 1.5rem;
    background: #f5f7fa;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .point-name {
      margin: 0;
      font-size: 1.6rem;
      font-weight: 600;
      color: #303133;
    }

    .el-tag {
      font-size: 1.2rem;
      padding: 0 1rem;
      height: 2.4rem;
      line-height: 2.4rem;
    }
  }

  .card-content {
    padding: 1.5rem;
    flex: 1;
    display: flex;
    flex-direction: column;

    .info-group {
      display: flex;
      align-items: flex-start;
      margin-bottom: 1.2rem;
      
      &.contact-group {
        min-height: 4.5rem;
      }

      i {
        color: #909399;
        margin-right: 0.8rem;
        margin-top: 0.3rem;
        font-size: 1.6rem;
        flex-shrink: 0;
      }

      .address {
        flex: 1;
        line-height: 1.5;
        font-size: 1.4rem;
      }

      .contact-info {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        font-size: 1.4rem;
      }
    }

    .info-row {
      display: flex;
      gap: 1.5rem;
      margin-bottom: 1rem;

      &.remark-row {
        min-height: 4rem;
      }

      .info-item {
        flex: 1;

        &.full-width {
          width: 100%;
        }

        .label {
          color: #909399;
          font-size: 1.3rem;
        }

        .value {
          margin-left: 0.5rem;
          color: #303133;
          font-size: 1.4rem;
        }
      }
    }
  }

  .card-footer {
    padding: 1.2rem 1.5rem;
    border-top: 1px solid #ebeef5;
    display: flex;
    justify-content: flex-end;
    margin-top: auto;

    .el-button {
      padding: 0.8rem 1.5rem;
      font-size: 1.3rem;
    }
  }
}
</style>