<template>
  <el-dialog top="5vh" :close-on-click-modal="false" :before-close="close" title="发货查看" width="750px" :visible.sync="show">
    <div class="goods-box">
      <el-table
          :data="listData"
          style="width: 100%"
      >
        <el-table-column label="商品" width="464px">
          <template slot-scope="scope">
            <div class="u-start-flex" style="align-items: flex-start">
              <el-image class="goods-img" :src="scope.row.Img"></el-image>
              <div style="width: 300px; height: 144px">
                <div class="title">{{ scope.row.ItemName }}</div>
                <div class="sub-title">{{ scope.row.GoodsName }}</div>
                <div class="u-start-flex">
                  <el-input-number size="mini" v-model="scope.row.Amount" :disabled="true"></el-input-number>
                  <div class="goods-price"><span>￥</span><span>{{ scope.row.Price }}</span></div>
                </div>
                <div class="priceText"><span class="symbol">￥</span>{{ scope.row.AllMoney }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="SKU" width="240px">
          <template slot-scope="scope">
            <div class="sku-box" style="height: 144px" v-if="scope.row.SkuId">
              <div class="u-start-flex title">
                <div class="mr10">{{ scope.row.SkuDetail.SKUCode }}</div>
                <div>{{ scope.row.SkuDetail.Name }}</div>
              </div>
              <div class="u-start-flex sub-title">
                <div class="mr10">{{ scope.row.SkuDetail.GoodsTypeName }}</div>
                <div>{{ scope.row.SkuDetail.ProductName }}</div>
              </div>
              <div class="sub-title">{{ `${scope.row.SkuDetail.Length}cm*${scope.row.SkuDetail.Width}cm*${scope.row.SkuDetail.Height}cm` }}</div>
              <div class="priceText">库存：{{ scope.row.SkuDetail.Quantity }}</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
import { getCodSKU } from "@/api/cod";

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    listData: {
      type: Array,
      default: () => []
    },
    OrderId: {
      type: Number,
      default: 0
    }
  },
  watch: {
    show: {
      handler(val) {
        if (val && this.QueryCodes.length) {
          this.getSku()
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
    }
  },
  computed: {
    QueryCodes() {
      return this.listData.filter(item => {
        return item.SkuId
      }).map(item => {
        return item.SkuId
      })
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    getSku() {
      const data = {
        PageIndex: 1,
        PageRows: 99,
        QueryCodeType: 1,
        QueryCodes: this.QueryCodes
      }
      getCodSKU(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.listData.forEach(item => {
            if (item.SkuId) {
              item['SkuDetail'] = Data.filter(sku => {
                return item.SkuId === sku.Id
              })[0]
            }
          })
        } else {
          this.$message.warning(Msg)
        }
      })
    }
  }
}

</script>

<style scoped lang="scss">
::v-deep .el-dialog__body {
  padding: 10px 20px;
}
.goods-box {
  .title {
    color: #11192d;
    font-family: PingFangSC-Semibold;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    //line-height: 30px;
  }
  .sub-title {
    border-radius: 8px;
    font-family: PingFang SC;
    font-weight: 500;
    height: 32px;
    line-height: 32px;
    margin-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: keep-all;
    color: #ff5000;
  }
  .priceText {
    margin-top: 10px;
    display: inline-block;
    font-style: italic;
    margin-right: 2px;
    vertical-align: baseline;
    font-family: AlibabaSans102v1TaoBao-Bold;
    font-size: 20px;
    line-height: 20px;
    vertical-align: baseline;
    color: #ff5000;
    .symbol {
      font-size: 12px;
    }
  }
  .goods-price {
    border-radius: 20px;
    float: left;
    margin-left: 8px;
    padding: 0 10px;
    background-color: rgb(255, 80, 0);
    color: rgb(255, 255, 255);
  }
  .goods-img {
    width: 144px;
    height: 144px;
    border-radius: 10px;
    margin-right: 12px;
  }
}
</style>