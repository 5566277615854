<template>
  <div class="apply-container">
    <div class="apply-title" v-if="mode==='create'">{{ $t('line.title') }}</div>
    <div class="form-box">
      <el-form
          v-loading="loading"
          size="mini"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          :element-loading-text="$t('line.loadingText')"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          label-width="80px"
          style="width: 35%"
          class="ruleForm">
        <el-form-item label="入仓号" prop="WarehouseEntryCode">
          <el-input v-model="ruleForm.WarehouseEntryCode">
            <template slot="suffix">
              <el-button size="mini" type="text" @click="printBarcode">打印</el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="客户单号" prop="ClientCode">
          <el-input v-model="ruleForm.ClientCode"></el-input>
        </el-form-item>
        <el-form-item :label="$t('line.GoodsName')" prop="GoodsName">
          <el-input v-model="ruleForm.GoodsName"></el-input>
        </el-form-item>
        <el-form-item label="申报数量" prop="DeclareAccount">
          <el-input v-model="ruleForm.DeclareAccount" type="number"></el-input>
        </el-form-item>
        <el-form-item :label="$t('line.GoodsTypeId')" prop="GoodsTypeId">
          <el-select style="width: 100%" v-model="ruleForm.GoodsTypeId" :placeholder="$t('line.placeholder')">
            <el-option
                v-for="item in goodTypes"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('line.TimerId')" prop="TimerId">
          <el-select style="width: 100%" v-model="ruleForm.TimerId" :placeholder="$t('line.placeholder')">
            <el-option
                v-for="item in timerOptions"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('line.channel')" prop="QuoteId">
          <el-select style="width: 100%" v-model="ruleForm.QuoteId" :placeholder="$t('line.placeholder')">
            <el-option
                v-for="item in ChannelOptions"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id">
            </el-option>
          </el-select>
        </el-form-item>
<!--        <el-form-item label="承运商" prop="CarrierId">-->
<!--          <el-select style="width: 100%" v-model="ruleForm.CarrierId" :placeholder="$t('line.placeholder')">-->
<!--            <el-option-->
<!--                v-for="item in CarrierOptions"-->
<!--                :key="item.Id"-->
<!--                :label="item.Name"-->
<!--                :value="item.Id">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item :label="$t('line.WareHouseId')" prop="WareHouseId">
          <el-select style="width: 100%" v-model="ruleForm.WareHouseId" :placeholder="$t('line.placeholder')">
            <el-option
                v-for="item in wareHouseOptions"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('line.customsClearance')" prop="IsCustomsClearance">
          <el-select style="width: 100%" v-model="ruleForm.IsCustomsClearance" :placeholder="$t('line.placeholder')">
            <el-option
                v-for="item in clearanceOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('line.Rem')" prop="Rem">
          <el-input type="textarea" v-model="ruleForm.Rem"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">{{ mode === 'create' ? $t('line.submitForm') : '确认修改' }}</el-button>
          <el-button @click="resetForm('ruleForm')">{{ $t('line.resetForm') }}</el-button>
        </el-form-item>
      </el-form>
      <div style="width: 60%">
        <address-select ref="addr" @refresh="init" @select-address="selectAddress" :options="AddrOptions"></address-select>
        <div class="mb20" v-if="mode==='create'">
          <el-upload
              class="upload"
              ref="upload"
              :multiple="false"
              :http-request="handleRequest"
              :before-upload="handleUpload"
              :on-exceed="handleExceed"
              :on-change="handleChange"
              :limit="1"
              action=""
              accept=".xls,.xlsx"
              :file-list="fileList"
              :auto-upload="false">
            <el-button slot="trigger" size="small" type="primary">{{ $t('line.uploadBoxForm') }}</el-button>
            <el-button style="margin-left: 10px;" size="small" type="success" @click="downloadTemplate">{{ $t('line.downloadBoxForm') }}</el-button>
            <div slot="tip" class="el-upload__tip">{{ $t('line.uploadTip') }}</div>
          </el-upload>
        </div>
        <el-button v-if="mode==='create'" size="mini" type="primary" @click="showVisible">{{ $t('line.addPackage') }}</el-button>
        <div class="mt20 u-between-flex" style="align-items: flex-start">
          <el-table
              v-if="mode==='create'"
              :data="lineWeightData"
              show-summary
              size="mini"
              style="width: 48%">
            <el-table-column
                type="index">
            </el-table-column>
            <el-table-column :label="`${$t('line.packageWeight')}  实重: ${actualWeight}(KG)`" align="center">
              <el-table-column prop="GrossWeight" :label="$t('line.grossWeightLabel')"></el-table-column>
              <el-table-column prop="TareWeight" :label="$t('line.tareWeightLabel')"></el-table-column>
              <el-table-column prop="Quantity" :label="$t('line.quantity')"></el-table-column>
            </el-table-column>
          </el-table>
          <el-table
              v-if="mode==='create'"
              :data="lineSizeData"
              show-summary
              size="mini"
              style="width: 48%">
            <el-table-column
                type="index">
            </el-table-column>
            <el-table-column :label="$t('line.packageSize')" align="center">
              <el-table-column prop="Length" :label="$t('line.lengthLabel')"></el-table-column>
              <el-table-column prop="Width" :label="$t('line.widthLabel')"></el-table-column>
              <el-table-column prop="Height" :label="$t('line.heightLabel')"></el-table-column>
              <el-table-column prop="Quantity" :label="$t('line.quantity')"></el-table-column>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog title="包裹信息" width="65%" :close-on-click-modal="false" :visible.sync="visible">
      <div class="mt20 medium-font">{{ $t('line.packageTip') }}</div>
      <div class="mt20 u-between-flex" style="align-items: flex-start">
        <div class="weight" style="width: 48%; border: 1px solid #eee; padding: 10px">
          <div class="medium-font mb10">{{ $t('line.packageWeight') }}：</div>
          <div class="u-between-flex title-line">
            <div style="width: 29%; text-indent: 15px">{{ $t('line.grossWeight') }}</div>
            <div style="width: 29%; text-indent: 15px">{{ $t('line.tareWeight') }}</div>
            <div style="width: 29%; text-indent: 15px">{{ $t('line.quantity') }}</div>
            <div style="width: 13%; text-indent: 15px">操作</div>
          </div>
          <line-weight-card :index="index" v-for="(item, index) in lineWeight" :key="index"></line-weight-card>
          <div class="operate mt20">
            <el-button size="mini" @click="addLineWeightArray" class="operate-btn mr8" type="primary" icon="el-icon-circle-plus-outline">{{ $t('apply.add') }}</el-button>
          </div>
        </div>
        <div class="size" style="width: 48%; border: 1px solid #eee; padding: 10px">
          <div class="medium-font mb10">{{ $t('line.packageSize') }}：</div>
          <div class="u-between-flex title-line" style="width: 100%">
            <div style="width: 22%; text-indent: 15px">{{ $t('line.length') }}</div>
            <div style="width: 22%; text-indent: 15px">{{ $t('line.width') }}</div>
            <div style="width: 22%; text-indent: 15px">{{ $t('line.height') }}</div>
            <div style="width: 22%; text-indent: 15px">{{ $t('line.quantity') }}</div>
            <div style="width: 12%; text-indent: 15px">操作</div>
          </div>
          <line-size-card :index="index" v-for="(item, index) in lineSize" :key="index"></line-size-card>
          <div class="operate mt20">
            <el-button size="mini" @click="addLineSizeArray" class="operate-btn mr8" type="primary" icon="el-icon-circle-plus-outline">{{ $t('apply.add') }}</el-button>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="visible = false">{{ $t('line.confirm') }}</el-button>
        <el-button size="small" type="primary" @click="confirmVisible">{{ $t('line.cancel') }}</el-button>
      </div>
    </el-dialog>
    <div style="display: none; position: relative">
      <img id="barcode" style="width: 80%; position: absolute; top: 20%; left: 50%; transform: translateX(-50%)" src="" alt="">
      <!--      <svg style="width: 40%; position: absolute; top: 20%; left: 50%; transform: translateX(-50%)" id="barcode"></svg>-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { getGoodsType, uploadImage } from "@/api/system"
import { getChannel } from "@/api/member";
import AddressSelect from "@/components/AddressSelect.vue";
import LineWeightCard from "@/views/member/apply/components/LineWeightCard.vue";
import LineSizeCard from "@/views/member/apply/components/LineSizeCard.vue";
import JsBarcode from "jsbarcode";
import printJS from "print-js";
import { dedicatedAddOrder, updateDedicatedOrder } from "@/api/dedicated"
export default {
  components: {LineWeightCard, AddressSelect, LineSizeCard},
  props: {
    mode: {
      type: String,
      default: "create"
    },
    orderData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      fileList: [],
      lineWeightData: [],
      lineSizeData: [],
      ruleForm: {
        ClientCode: "",
        IsCustomsClearance: false,
        GoodsName: "",
        GoodsTypeId: "",
        TimerId: "",
        WareHouseId: "",
        Rem: ""
      },
      visible: false,
      AddrOptions: [],
      CarrierOptions: [],
      rules: {
        // GoodsTypeId: [
        //   { required: true, message: this.$t('line.placeholderGoodsTypeId'), trigger: 'change' }
        // ],
        // ChannelId: [
        //     { required: true, message: this.$t('mergerCard.placeholderChannel'), trigger: 'blur' }
        // ],
        // TimerId: [
        //   { required: true, message: this.$t('line.placeholderTimerId'), trigger: 'change' }
        // ],
        // WareHouseId: [
        //   { required: true, message: this.$t('line.placeholderWareHouseId'), trigger: 'change' }
        // ],
        WarehouseEntryCode: [
          { required: true, message: '请填写入仓号', trigger: 'blur' }
        ],
        ClientCode: [
        ],
        // GoodsName: [
        //   { required: true, message: this.$t('line.placeholderGoodsName'), trigger: 'blur' }
        // ]
      },
      wareHouseOptions: [],
      timerOptions: [],
      destinationOptions: [],
      ChannelOptions: [],
      clearanceOptions: [
        {
          label: this.$t('line.yes'),
          value: true
        },
        {
          label: this.$t('line.no'),
          value: false
        }
      ],
      loading: false,

    }
  },
  computed: {
    ...mapGetters(['goodTypes', 'TenantId', 'lineWeight', 'lineSize']),
    actualWeight() {
      let weight = 0
      this.lineWeightData.forEach(item => {
        weight += ( parseFloat(item.GrossWeight) - parseFloat(item.TareWeight) )
      })
      return weight
    },
    currentAddress() {
      if (this.ruleForm.ClientAddressId) {
        return this.AddrOptions.filter(item => {
          return item.Id === this.ruleForm.ClientAddressId
        })[0]
      } else {
        return {}
      }
    },
    validateChannel() {
      if (this.mode === 'create' && this.ruleForm.ClientAddressId) {
        return true
      } else if (this.mode === 'update' && this.ruleForm.ClientAddressId) {
        return true
      } else if (this.mode === 'update' && this.orderData.DestinationId) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    "ruleForm.TimerId": {
      handler(newVal) {
        if (newVal && this.validateChannel) {
          this.resetChannel()
        }
      },
      immediate: true
    }
  },
  methods: {
    filterStoreArray() {
      const newWeightArray = JSON.parse(JSON.stringify(this.lineWeight)).filter(item => {
        return item.GrossWeight && item.Quantity
      })
      const newSizeArray = JSON.parse(JSON.stringify(this.lineSize)).filter(item => {
        return item.Length && item.Width && item.Height && item.Quantity
      })
      this.lineWeightData = newWeightArray
      this.lineSizeData = newSizeArray
      this.$store.commit('consolidation/SET_LINE_WEIGHT', newWeightArray)
      this.$store.commit('consolidation/SET_LINE_SIZE', newSizeArray)
    },
    confirmVisible() {
      this.filterStoreArray()
      this.visible = false
      this.$forceUpdate()
    },
    showVisible() {
      if (!this.lineWeight.length) {
        this.addLineWeightArray()
      }
      if (!this.lineSize.length) {
        this.addLineSizeArray()
      }
      this.visible = true
    },
    printBarcode() {
      if (!this.ruleForm.WarehouseEntryCode) {
        return;
      }
      JsBarcode("#barcode", this.ruleForm.WarehouseEntryCode, {
        fontSize: 40,
        width: 4,
        fontOptions: "bold",
      })
      setTimeout(() => {
        printJS({
          type: 'html',
          printable: "barcode",
          scanStyles: false, //不适用默认样式
          style: '@page{size:auto;margin: 0cm 1cm 0cm 1cm;opacity: 0;}',
        })
      }, 500)
    },
    downloadTemplate() {
      const url = `${process.env.VUE_APP_BASE_API}ZBFile/1/OrderPackingListTemplate/装箱单模板.xls`
      window.open(url, '_blank')
    },
    handleRequest() {},
    addLineWeightArray() {
      this.$store.dispatch('consolidation/addLineWeightArray')
    },
    addLineSizeArray() {
      this.$store.dispatch('consolidation/addLineSizeArray')
    },
    handleExceed(files) {
      this.$refs.upload.clearFiles();
      this.fileList = [files[0]]
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },
    handleUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isLt2M) {
        this.$message.error("文件超出大小");
      }
      return isLt2M;
    },
    getGoodsType() {
      const data = {
        pageIndex: 1,
        pageRows: 10
      }
      getGoodsType(data).then(response => {
        const { Data } = response;
        this.$store.dispatch('consolidation/changeGoodTypes', Data)
      })
    },
    resetChannel() {
      const data = {
        TimerId: this.ruleForm.TimerId,
        AddressId: this.ruleForm.ClientAddressId,
        DestinationId: this.mode === 'update' && !this.ruleForm.ClientAddressId ? this.orderData.DestinationId : undefined
      }
      getChannel(data).then(response => {
        this.ChannelOptions = response.Data.ChannelList
        if (!this.ChannelOptions.map(item => { return item.Id }).includes(this.ruleForm.QuoteId)) {
          this.$set(this.ruleForm, 'QuoteId', undefined)
        }
      })
    },
    getCarrier() {
      this.$store.dispatch('webSite/getCarrier').then(response => {
        this.CarrierOptions = response
      })
    },
    getWareHouse() {
      this.$store.dispatch('webSite/getWareHouse').then(response => {
        this.wareHouseOptions = response
      })
    },
    getTimer() {
      this.$store.dispatch('webSite/getTimer').then(response => {
        this.timerOptions = response
      })
    },
    init() {
      return new Promise((rs) => {
        this.$store.dispatch('webSite/getAddress').then(data => {
          this.AddrOptions = data;
          this.AddrOptions.sort((a, b) => {
            if (a.IsDefaultAddress && !b.IsDefaultAddress) return -1;
            if (!a.IsDefaultAddress && b.IsDefaultAddress) return 1;
            return 0;
          });
          const defaults = this.AddrOptions.filter(item => { return item.IsDefaultAddress })
          if (this.mode === 'update') {
            const addrNav = {
              DestinationName: this.orderData.DestinationName,
              ProvinceName: this.orderData.ProvinceName,
              CityName: this.orderData.CityName,
              AreaName: this.orderData.AreaName,
              RecipientName: this.orderData.ReceiveName,
              RecipientPhone: this.orderData.ReceivePhone,
              RecipientAddress: this.orderData.ReceiveAddress
            }
            this.$refs.addr.onNav(addrNav)
            rs(false)
          } else {
            if (defaults.length) {
              this.$refs.addr.onNav(defaults[0])
            } else if(this.AddrOptions.length) {
              this.$refs.addr.onNav(this.AddrOptions[0])
            } else {
              rs(false)
            }
          }
          rs(true)
        })
      })
    },
    selectAddress(itemId) {
      this.$set(this.ruleForm, 'ClientAddressId', itemId)
      if (this.ruleForm.TimerId) {
        this.resetChannel()
      }
    },
    uploadExcel() {
      return new Promise((resolve, reject) => {
        if (this.fileList.length) {
          const formData = new FormData();
          formData.append('file', this.fileList[0].raw || this.fileList[0]);
          formData.append('Path', 'OrderPackingList');
          this.loading = true
          uploadImage(formData).then(response => {
            const { Code, Msg, Data } = response
            if (Code === 200) {
              resolve(Data.ServerPath)
            } else {
              reject(Msg)
            }
          })
        } else {
          resolve("")
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.mode === 'create') {
            const path = await this.uploadExcel()
            const data = {
              CustomerNumber: this.ruleForm.ClientCode,
              WarehouseEntryCode: this.ruleForm.WarehouseEntryCode,
              GoodsName: this.ruleForm.GoodsName,
              ClientAddressId: this.ruleForm.ClientAddressId,
              WareHouseId: this.ruleForm.WareHouseId || undefined,
              GoodsTypeId: this.ruleForm.GoodsTypeId || undefined,
              CarrierId: this.ruleForm.CarrierId || undefined,
              PackingListLink: path,
              DeclareAccount: this.ruleForm.DeclareAccount,
              TimerId: this.ruleForm.TimerId || undefined,
              IsCustomsClearance: this.ruleForm.IsCustomsClearance,
              QuoteId: this.ruleForm.QuoteId || undefined,
              Rem: this.ruleForm.Rem,
              WeightInfo: this.lineWeightData,
              SizeInfo: this.lineSizeData,
            }
            this.loading = true
            dedicatedAddOrder(data).then(response => {
              this.loading = false
              const {Code, Msg} = response
              if (Code === 200) {
                this.$message.success(this.$t('line.submitSuccess'))
                this.resetForm("ruleForm")
              } else {
                this.$message.warning(Msg)
              }
            })
          } else {
            const data = {
              Id: this.orderData.Id,
              CustomerNumber: this.ruleForm.ClientCode,
              WarehouseEntryCode: this.ruleForm.WarehouseEntryCode,
              GoodsName: this.ruleForm.GoodsName,
              DestinationId: this.ruleForm.ClientAddressId ? this.currentAddress.DestinationId : this.orderData.DestinationId,
              ProvinceId: this.ruleForm.ClientAddressId ? this.currentAddress.ProvinceId : this.orderData.ProvinceId,
              CityId: this.ruleForm.ClientAddressId ? this.currentAddress.CityId : this.orderData.CityId,
              AreaId: this.ruleForm.ClientAddressId ? this.currentAddress.AreaId : this.orderData.AreaId,
              AgentPointId: this.ruleForm.ClientAddressId ? this.currentAddress.AgentPointId : this.orderData.AgentPointId,
              ReceiveName: this.ruleForm.ClientAddressId ? this.currentAddress.RecipientName : this.orderData.ReceiveName,
              ReceivePhone: this.ruleForm.ClientAddressId ? this.currentAddress.RecipientPhone : this.orderData.ReceivePhone,
              ReceiveTelephone: this.ruleForm.ClientAddressId ? this.currentAddress.RecipientTelephone : this.orderData.ReceiveTelephone,
              ReceiveIdCard: this.ruleForm.ClientAddressId ? this.currentAddress.RecipientIdCard : this.orderData.ReceiveIdCard,
              ReceivePostCode: this.ruleForm.ClientAddressId ? this.currentAddress.RecipientPostCode : this.orderData.ReceivePostCode,
              ReceiveEmail: this.ruleForm.ClientAddressId ? this.currentAddress.RecipientEmail : this.orderData.ReceiveEmail,
              ReceiveAddress: this.ruleForm.ClientAddressId ? this.currentAddress.RecipientAddress : this.orderData.ReceiveAddress,
              WareHouseId: this.ruleForm.WareHouseId || undefined,
              GoodsTypeId: this.ruleForm.GoodsTypeId || undefined,
              CarrierId: this.ruleForm.CarrierId || undefined,
              DeclareAccount: this.ruleForm.DeclareAccount,
              TimerId: this.ruleForm.TimerId || undefined,
              IsCustomsClearance: this.ruleForm.IsCustomsClearance,
              QuoteId: this.ruleForm.QuoteId || undefined,
              Rem: this.ruleForm.Rem,
            }
            this.loading = true
            updateDedicatedOrder(data).then(response => {
              this.loading = false
              const {Code, Msg} = response
              if (Code === 200) {
                this.$emit("refresh")
              } else {
                this.$message.warning(Msg)
              }
            }).catch(() => {
              this.loading = false
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    initializeForm() {
      this.init().then(() => {
        this.getTimer()
      })
      this.getCarrier()
      this.getGoodsType()
      this.getWareHouse()
      this.$store.dispatch('consolidation/resetLineTemp')
      if (this.mode === 'update') {
        this.$set(this, 'ruleForm', JSON.parse(JSON.stringify(this.orderData)));
        this.$forceUpdate()
      }
    },
    resetForm(formName) {
      if (this.mode === 'create') {
        this.$set(this, formName, {
          ClientCode: "",
          GoodsName: "",
          Rem: "",
          // GoodsTypeId: this.goodTypes[0]?.Id,
          ClientAddressId: this.ruleForm.ClientAddressId,
          IsCustomsClearance: false,
          // TimerId: this.timerOptions[0]?.Id,
          // WareHouseId: this.wareHouseOptions[0]?.Id
        })
        this.lineSizeData = []
        this.lineWeightData = []
        this.$store.dispatch('consolidation/resetLineTemp')
        this.$refs[formName].resetFields();
      } else {
        this.$set(this, 'ruleForm', JSON.parse(JSON.stringify(this.orderData)));
        this.$forceUpdate()
      }
    }
  },
  created() {

  }
}
</script>

<style scoped lang="scss">
::v-deep .address-box .addr-box {
  height: 16rem;
}
::v-deep .el-dialog__body {
  padding: 15px;
}
::v-deep .address-box {
  margin-top: 0;
}
.dialog-footer {
  text-align: left;
}
.title-line {
  width: 99%;
  font-size: 12px;
  background-color: #e5dddd;
  padding: 0.5rem 0;
}
.apply-container {
  padding: 4rem 2rem;
  .apply-title {
    padding: 1.5rem 2.5rem;
    color: #225493;
    background-color: #F0F4FB;
    font-size: 1.6rem;
  }
  .form-box {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

}
</style>
