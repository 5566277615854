<template>
  <div class="advantage">
    <div class="advantage-title">{{ $t('advantagebox.title') }}</div>
    <div class="advantage-container">
      <div class="advantage-item" v-for="item in advantages" :key="item.id">
        <el-image style="width: 8rem; height: auto" :src="require(`@/static/images/index/advantage/${item.icon}`)"></el-image>
        <div class="advantage-fst-title">{{ item.title }}</div>
        <div class="advantage-line"></div>
        <div class="advantage-sub-title">{{ item.sub_title1 }}</div>
        <div class="advantage-sub-title">{{ item.sub_title2 }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  computed: {
    advantages() {
      return [
        {
          id: 1,
          icon: 'team.png',
          title: this.$t('advantageBox.team.title'),
          sub_title1: this.$t('advantageBox.team.subtitle1'),
          sub_title2: this.$t('advantageBox.team.subtitle2')
        },
        {
          id: 2,
          icon: 'one.png',
          title: this.$t('advantageBox.qualityControl.title'),
          sub_title1: this.$t('advantageBox.qualityControl.subtitle1'),
          sub_title2: this.$t('advantageBox.qualityControl.subtitle2')
        },
        {
          id: 3,
          icon: 'safe.png',
          title: this.$t('advantageBox.innovativeTechnology.title'),
          sub_title1: this.$t('advantageBox.innovativeTechnology.subtitle1'),
          sub_title2: this.$t('advantageBox.innovativeTechnology.subtitle2')
        },
        {
          id: 4,
          icon: '24hour.png',
          title: this.$t('advantageBox.diverseService.title'),
          sub_title1: this.$t('advantageBox.diverseService.subtitle1'),
          sub_title2: this.$t('advantageBox.diverseService.subtitle2')
        }
      ]
    }
  },
  methods: {

  }

}

</script>

<style scoped lang="scss">
.advantage {
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), 
                    url('../../../static/images/advantage-bgc.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed; // 视差滚动效果
  padding: 8rem 0;
  
  .advantage-title {
    text-align: center;
    font-size: 3.2rem;
    font-weight: 600;
    color: white;
    margin-bottom: 5rem;
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -1rem;
      left: 50%;
      transform: translateX(-50%);
      width: 6rem;
      height: 3px;
      background: linear-gradient(90deg, #307CC0, #64B5F6);
      border-radius: 2px;
    }
  }

  .advantage-container {
    width: 85%;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;

    .advantage-item {
      flex: 1;
      text-align: center;
      background: rgba(255, 255, 255, 0.05);
      backdrop-filter: blur(10px);
      border-radius: 1rem;
      padding: 3rem 2rem;
      transition: all 0.3s ease;
      
      &:hover {
        transform: translateY(-10px);
        background: rgba(255, 255, 255, 0.1);
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
      }

      .el-image {
        width: 8rem;
        height: 8rem;
        margin-bottom: 2rem;
        filter: drop-shadow(0 5px 15px rgba(48, 124, 192, 0.3));
        transition: all 0.3s ease;

        &:hover {
          transform: scale(1.1);
        }
      }

      .advantage-fst-title {
        font-size: 2.2rem;
        font-weight: 600;
        color: #fff;
        margin: 2rem 0;
      }

      .advantage-line {
        width: 40%;
        height: 2px;
        background: linear-gradient(90deg, transparent, #307CC0, transparent);
        margin: 1.5rem auto;
      }

      .advantage-sub-title {
        font-size: 1.4rem;
        color: rgba(255, 255, 255, 0.8);
        margin: 1rem 0;
        line-height: 1.6;
      }
    }
  }

  // 响应式设计
  @media screen and (max-width: 1200px) {
    .advantage-container {
      width: 90%;
      gap: 1.5rem;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 6rem 0;
    
    .advantage-title {
      font-size: 2.8rem;
      margin-bottom: 4rem;
    }

    .advantage-container {
      flex-direction: column;
      width: 85%;
      
      .advantage-item {
        margin-bottom: 2rem;
        
        &:hover {
          transform: translateY(-5px);
        }

        .advantage-fst-title {
          font-size: 2rem;
        }

        .advantage-sub-title {
          font-size: 1.2rem;
        }
      }
    }
  }
}
</style>
