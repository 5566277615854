import request from "@/utils/requests"

export function getList(data) {
    return request({
        url: '/Dedicated/GetDedicatedDataPage',
        method: 'post',
        data
    })
}

export function getPdfByte(data) {
    return request({
        url: '/Dedicated/DedicatedOrderClientPrint',
        method: 'post',
        data
    })
}

export function dedicatedAddOrder(data) {
    return request({
        url: '/Dedicated/DedicatedAddOrder',
        method: 'post',
        data
    })
}

export function updateDedicatedOrder(data) {
    return request({
        url: '/Dedicated/DedicatedUpdateOrder',
        method: 'post',
        data
    })
}