<template>
  <div class="container">
    <scroll-notice></scroll-notice>
    <div class="layout">
      <div class="navigation">
        <div class="navigation-logo">
          <el-image style="width: 5rem" :src="require('@/static/images/member/huochuan.png')" fit="cover"></el-image>
          <div class="navigation-title">{{ $t('personal.membercenter') }}</div>
        </div>
        <member-menu></member-menu>
      </div>
      <div class="operate">
        <transition name="custom-transition" mode="out-in">
          <router-view></router-view>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import memberMenu from "@/views/member/components/member-menu.vue";
import ScrollNotice from "@/views/member/components/ScrollNotice.vue";
export default {
  data() {
    return {
    }
  },
  created() {
    this.$store.dispatch('webSite/getPayTypes')
  },
  components: {
    ScrollNotice,
    memberMenu
  },
  methods: {
    getNotification() {}
  }
}

</script>

<style scoped lang="scss">
.custom-transition-enter-active, .custom-transition-leave-active {
  transition: all 0.5s;
}
::-webkit-scrollbar {
  display: none;
}
.custom-transition-enter, .custom-transition-leave-to /* .custom-transition-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateY(-30px);
}
.container {
  width: 100%;
  .layout {
    display: flex;
    justify-content: space-between;
    .navigation {
      width: 25rem;
      height: calc(100vh - 8rem - 8px - 30px);
      overflow-y: scroll;
      .navigation-logo {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        background-color: #fff;
        padding: 1.8rem 1.2rem;
        border: 0.1rem solid rgba(237,237,237,1);
        .navigation-title {
          font-size: 2.4rem;
          font-weight: bold;
          color: #194D8F;
        }
      }
    }
    .operate {
      background-color: #fff;
      width: calc(100vw - 25rem - 1.5rem);
      height: calc(100vh - 8rem - 40px);
      overflow-y: scroll;
    }
  }
}

</style>
