<template>
  <div class="coupon-box" :style="{'flex': `0 0 ${basicWidth}`, marginRight: basicWidth==='15%' ? '1.5rem' : 'none', marginBottom: '1.5rem'}">
    <div class="coupon-header-box">
      <span class="media-font">{{ $t('coupon.discountcoupon')}}</span>
      <span class="title-font">{{ coupon.Amount }}</span>
    </div>
    <div class="coupon-introduce-box">
      <div>
        <div class="mb8">{{ $t('coupon.effectivetime') }}：{{ coupon.EffectiveTime | formatDate }}</div>
        <div class="mb8">{{ $t('coupon.expiredtime') }}：{{ coupon.ExpiredTime | formatDate }}</div>
        <div class="mb8">{{ coupon.Name }}</div>
        <div>{{ coupon.Rem }}</div>
      </div>
    </div>
    <div class="u-center-flex" style="height: 7rem" v-if="coupon.Status === 2">
      <button class="jump-btn" @click="$router.push({ path: '/member/consolidated-domestic' })">{{ $t('coupon.use') }}</button>
    </div>
    <div class="u-center-flex" style="height: 7rem" v-else-if="coupon.Status === 4">
      <el-image :src="require('@/static/images/member/coupon/yishiyong.png')" style="height: 4.5rem"></el-image>
    </div>
    <div class="u-center-flex" style="height: 7rem" v-else-if="coupon.Status === 1">
      <el-image :src="require('@/static/images/member/coupon/weishengxiao.png')" style="height: 4.5rem"></el-image>
    </div>
    <div class="u-center-flex" style="height: 7rem" v-else-if="coupon.Status === 3">
      <el-image :src="require('@/static/images/member/coupon/yishixiao.png')" style="height: 4.5rem"></el-image>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {},
  computed: {
    basicWidth() {
      if (this.length <= 5) {
        return '15%'
        // return `${ 95 / this.length }%`
      } else {
        return '15%'
      }
    }
  },
  props: ['coupon', 'length']
}
</script>

<style scoped lang="scss">
.coupon-box {
  position: relative;
  height: auto;
  border: none;
  border-radius: 1.2rem;
  display: inline-block;
  background: #fff;
  box-shadow: 
    0 0.4rem 1.2rem rgba(0, 0, 0, 0.08),
    inset 0 0 0 0.1rem rgba(255, 255, 255, 0.5),
    inset 0 0 0.8rem rgba(255, 125, 53, 0.05);
  transition: all 0.3s ease;
  overflow: hidden;
  
  &:hover {
    transform: translateY(-0.3rem);
    box-shadow: 
      0 0.6rem 1.6rem rgba(0, 0, 0, 0.12),
      inset 0 0 0 0.1rem rgba(255, 255, 255, 0.6),
      inset 0 0 1rem rgba(255, 125, 53, 0.08);
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0.6rem;
    height: 100%;
    background: linear-gradient(90deg, 
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.2),
      transparent
    );
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 0.4rem;
    height: 100%;
    background: linear-gradient(270deg,
      rgba(0, 0, 0, 0.03),
      transparent
    );
  }

  .coupon-header-box {
    height: 14rem;
    width: 100%;
    background: linear-gradient(135deg, #FF7D35 0%, #FF9B59 100%);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 1.2rem 1.2rem 0 0;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 0.4rem;
      background: linear-gradient(180deg,
        transparent,
        rgba(0, 0, 0, 0.05)
      );
    }

    &::after {
      content: '';
      position: absolute;
      right: -2rem;
      top: -2rem;
      width: 12rem;
      height: 12rem;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
    }

    .media-font {
      font-size: 1.8rem;
      color: rgba(255, 255, 255, 0.9);
      margin-bottom: 0.8rem;
    }

    .title-font {
      font-size: 4.2rem;
      color: #ffffff;
      font-weight: 700;
      text-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
    }
  }

  .coupon-introduce-box {
    width: auto;
    height: 14rem;
    position: relative;
    font-size: 1.3rem;
    color: #666;
    background: linear-gradient(
      180deg,
      #fff 0%,
      #FAFAFA 100%
    );
    padding: 1.5rem;
    line-height: 1.6;
    border-left: 0.1rem solid rgba(0, 0, 0, 0.03);
    border-right: 0.1rem solid rgba(0, 0, 0, 0.03);

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 0.1rem;
      background-image: linear-gradient(to right, #E8E8E8 50%, transparent 50%);
      background-size: 1.5rem 0.1rem;
      background-repeat: repeat-x;
    }

    .mb8 {
      margin-bottom: 1rem;
      &:last-child {
        color: #999;
        font-size: 1.2rem;
      }
    }
  }
}

.coupon-box::before,
.coupon-box::after {
  content: "";
  position: absolute;
  top: 14rem;
  width: 2rem;
  height: 2rem;
  background: linear-gradient(
    135deg,
    #fff 0%,
    #FAFAFA 100%
  );
  border-radius: 50%;
  z-index: 2;
  box-shadow: 
    0 0 0.4rem rgba(0, 0, 0, 0.1),
    inset 0 0 0.2rem rgba(0, 0, 0, 0.05);
}

.u-center-flex {
  background: linear-gradient(
    0deg,
    #FAFAFA 0%,
    #fff 100%
  );
  border-radius: 0 0 1.2rem 1.2rem;
  position: relative;
  border-left: 0.1rem solid rgba(0, 0, 0, 0.03);
  border-right: 0.1rem solid rgba(0, 0, 0, 0.03);
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.03);
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 2rem;
    right: 2rem;
    height: 0.2rem;
    box-shadow: 
      0 -0.2rem 0.4rem rgba(0, 0, 0, 0.05),
      inset 0 0.1rem 0.2rem rgba(255, 255, 255, 0.8);
  }
  
  .el-image {
    opacity: 0.8;
    transition: opacity 0.3s ease;
    
    &:hover {
      opacity: 1;
    }
  }
}

.jump-btn {
  background: linear-gradient(135deg, #FF7D35 0%, #FF9B59 100%);
  border: none;
  width: 13rem;
  height: 3.6rem;
  border-radius: 1.8rem;
  color: #fff;
  font-size: 1.6rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transition: 0.5s;
  }
  
  &:hover {
    transform: translateY(-0.2rem);
    box-shadow: 0 0.4rem 0.8rem rgba(255, 125, 53, 0.3);
    
    &::after {
      left: 100%;
    }
  }
  
  &:active {
    transform: translateY(0);
  }
}
</style>
