<template>
  <div class="container">
    <div class="title">{{ $t('comment.mainTitle') }}</div>
    <div class="sub-title">{{ $t('comment.subTitle') }}</div>
    <vue-seamless-scroll class="scroll_box" :data="listData" :class-option="defineScroll">
      <div class="grid-box" v-for="(item, i) in listData" :key="i">
        <div class="item" v-for="comment in item" :key="comment.Id">
          <div class="head u-between-flex">
            <div class="u-start-flex">
              <el-image :src="comment.Clientprofile" class="head-img mr8"></el-image>
              <div>{{ comment.ClientName }}</div>
            </div>
            <el-rate disabled v-model="comment.StarLevel"></el-rate>
          </div>
          <div class="body">
            <div class="mb20">{{ comment.ReviewContent }}</div>
            <div v-if="comment.ReplyContent" class="replay">{{ $t('comment.reply') }}：{{ comment.ReplyContent }}</div>
          </div>
          <div class="date u-between-flex">
            <div>{{ comment.CreateTime | formatDate }}</div>
            <el-button plain icon="el-icon-thumb" round size="mini">999+</el-button>
          </div>
        </div>
      </div>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import {getComment} from "@/api/system";
import {mapGetters} from "vuex";
export default {
  data() {
    return {
      comments: []
    }
  },
  methods: {
    getData() {
      const data = {
        PageIndex: 1,
        PageRows: 100,
        ShopId: this.shopID,
        TenantId: this.TenantId
      }
      getComment(data).then(response => {
        const { Code, Data } = response
        if (Code === 200) this.comments = Data
      })
    }
  },
  created() {
    this.getData()
  },
  computed: {
    ...mapGetters(['shopID', 'TenantId']),
    listData() {
      let result = []
      for (let i = 0; i < this.comments.length; i+=6) {
        result.push(this.comments.slice(i, i + 6))
      }
      return result
    },
    defineScroll() {
      return {
        step: 0.4,
        limitMoveNum: 2,
        hoverStop: true,
        direction: 1,
        openWatch: true,
        singleHeight: 0,
        singleWidth: 0,
        waitTime: 1200,
      };
    },
  },
}

</script>

<style scoped lang="scss">
.container {
  width: 100%;
  background-color: #fff;
  padding: 8rem 0;

  .title {
    width: 100%;
    text-align: center;
    font-size: clamp(2.8rem, 3vw, 4rem);
    font-weight: 600;
    background: linear-gradient(45deg, #2196F3, #4CAF50);
    -webkit-background-clip: text;
    color: transparent;
    margin-bottom: 2rem;
  }

  .sub-title {
    width: 100%;
    text-align: center;
    font-size: clamp(1.8rem, 2vw, 2.4rem);
    color: #666;
    margin-bottom: 4rem;
  }

  .scroll_box {
    overflow: hidden;
    width: 90%;
    margin: 0 auto;
    height: 50rem;
  }

  .grid-box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    padding: 0.8rem;
    
    .item {
      background: #fff;
      border-radius: 12px;
      padding: 2rem;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
      transition: all 0.3s ease;
      border: 1px solid #f0f0f0;
      margin-bottom: 1.5rem;
      
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
      }

      .head {
        margin-bottom: 2.5rem;
        
        .head-img {
          width: 5rem;
          height: 5rem;
          border-radius: 50%;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
          transition: transform 0.3s ease;
          
          &:hover {
            transform: scale(1.1);
          }
        }

        .u-start-flex {
          div {
            font-size: clamp(1.6rem, 1.5vw, 2rem);
            font-weight: 500;
            margin-left: 1.5rem;
          }
        }

        :deep(.el-rate) {
          transform: scale(1.2);
          transform-origin: right;
          .el-icon-star-on {
            color: #FFB800;
          }
        }
      }

      .body {
        color: #333;
        line-height: 1.8;
        margin-bottom: 2rem;
        font-size: clamp(1.5rem, 1.3vw, 1.8rem);
        
        .mb20 {
          margin-bottom: 2rem;
        }
        
        .replay {
          margin-top: 2rem;
          padding: 1.5rem;
          background: #FFF5F5;
          border-radius: 8px;
          color: #FF4D4F;
          font-size: clamp(1.4rem, 1.2vw, 1.7rem);
        }
      }

      .date {
        color: #999;
        font-size: clamp(1.3rem, 1.1vw, 1.6rem);
        padding-top: 1.8rem;
        border-top: 1px dashed #eee;
        
        :deep(.el-button) {
          transition: all 0.3s ease;
          padding: 0.8rem 1.5rem;
          font-size: clamp(1.3rem, 1.1vw, 1.6rem);
          
          &:hover {
            background: #2196F3;
            color: white;
            border-color: #2196F3;
          }
        }
      }
    }
  }
}
</style>