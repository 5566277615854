<template>
  <el-drawer
      title="店铺筛选"
      :visible.sync="drawer"
      :direction="direction"
      append-to-body
      :before-close="handleClose">
    <div class="query-boxes">
      <div class="query-items" v-for="item in listData" :key="item.field">
        <div class="title">{{ item.title }}</div>
        <div class="query-options">
          <div @click="onNav(item.field, nav.value)" :class="{ 'btn_active' : query[item.field] === nav.value }" class="option" v-for="nav in item.options" :key="nav.value">{{ nav.label }}</div>
        </div>
      </div>
      <div class="query-items">
        <div class="title">下单时间</div>
        <el-date-picker
            size="mini"
            v-model="query.dateRange"
            type="datetimerange"
            :range-separator="$t('order.separator')"
            :start-placeholder="$t('order.startTime')"
            :end-placeholder="$t('order.endTime')">
        </el-date-picker>
      </div>
    </div>
    <div class="query-btn">
      <el-button style="width: 40%" size="small" type="primary" @click="confirm">确定</el-button>
      <el-button style="width: 40%" size="small" type="info" @click="toReset">重置</el-button>
    </div>
  </el-drawer>
</template>

<script>

import {getGoodsType} from "@/api/system";

export default {
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    initialQuery: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    drawer: {
      handler(val) {
        if (val) {
          this.query = this.initialQuery
          this.$forceUpdate()
        }
      }
    }
  },
  data() {
    return {
      query: {
        dateRange: []
      },
      listData: [],
      direction: 'rtl',
    }
  },
  mounted() {
    this.$store.dispatch('webSite/getCarrier').then(data => {
      this.listData.push({
        title: "承运商",
        field: "carrier",
        options: data.map(item => {
          return {
            label: item.Name,
            value: item.Id
          }
        })
      })
    })
    this.$store.dispatch('webSite/getWareHouse').then(data => {
      this.listData.push({
        title: "仓库",
        field: "warehouse",
        options: data.map(item => {
          return {
            label: item.Name,
            value: item.Id
          }
        })
      })
    })
    this.$store.dispatch('webSite/getCountry').then(data => {
      this.listData.push({
        title: "目的地",
        field: "destination",
        options: data.map(item => {
          return {
            label: item.Name,
            value: item.Id
          }
        })
      })
    })
    this.$store.dispatch('webSite/getTimer').then(data => {
      this.listData.push({
        title: "时效",
        field: "timer",
        options: data.map(item => {
          return {
            label: item.Name,
            value: item.Id
          }
        })
      })
    })
    this.getGoodsType()
  },
  methods: {
    toReset() {
      this.query = {
        dateRange: []
      }
    },
    getGoodsType() {
      const data = {
        pageIndex: 1,
        pageRows: 10
      }
      getGoodsType(data).then(response => {
        const { Data } = response;
        this.listData.push({
          title: "货物类型",
          field: "GoodsType",
          options: Data.map(item => {
            return {
              label: item.Name,
              value: item.Id
            }
          })
        })
      })
    },
    confirm() {
      this.$emit("handle-confirm", this.query)
    },
    handleClose() {
      this.$emit('close')
    },
    onNav(field, val) {
      if (this.query[field] === val) {
        this.$set(this.query, field, undefined)
      } else {
        this.$set(this.query, field, val)
      }
    }
  }
}

</script>

<style scoped lang="scss">
::v-deep #el-drawer__title {
  font-size: 16px;
}
.query-btn {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.query-boxes {
  padding: 0 20px;
  margin-bottom: 1.5rem;
  height: calc(100vh - 96px - 32px - 2rem);
  overflow-y: scroll;
  .query-items {
    .title {
      font-size: 14px;
      color: #353535;
      margin-bottom: 1.5rem;
    }
    .query-options {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .option {
        cursor: pointer;
        font-size: 12px;
        padding: 5px 10px;
        border-radius: 5px;
        background: #dcdfe6;
        margin: 0 10px 10px 0;
        color: #fff;
      }
      .btn_active {
        background: #53EC7B !important;
      }
    }
  }
}
</style>