<template>
<div class="immediate-container">
  <div class="immediate-title">{{ $t('immediate.title') }}</div>
  <div class="claim-box">
    <div class="claim-title">
      <div style="width: 20%">{{ $t('apply.code') }}</div>
      <div style="width: 20%">{{ $t('apply.goodsname') }}</div>
      <div style="width: 10%">{{ $t('apply.goodstype') }}</div>
      <div style="width: 10%">{{ $t('apply.account') }}</div>
      <div style="width: 10%">{{ $t('apply.price') }}</div>
      <div style="width: 10%">{{ $t('apply.totalprice') }}</div>
    </div>
    <div class="claim-body">
      <single-card :index="index" :rem="false" size="mini" v-for="(item, index) in singleReports" :key="index"></single-card>
      <div class="operate mt20">
        <el-button size="mini" @click="addSingle" class="operate-btn mr8" type="primary" icon="el-icon-circle-plus-outline">{{ $t('apply.add') }}</el-button>
        <el-button size="mini" class="operate-btn" type="primary" @click="removeSingle">{{ $t('apply.remove') }}</el-button>
      </div>
    </div>
  </div>
  <div class="form-box">
    <address-select style="width: 65%" ref="addr" @refresh="init" @select-address="selectAddress" :options="AddrOptions"></address-select>
    <el-form ref="ruleForm" :rules="rules" size="mini" class="immediate-form" :model="form" label-width="80px">
      <el-form-item :label="$t('immediate.timer')">
        <el-select style="width: 100%;" v-model="form.TimerId" :placeholder="$t('immediate.placeholderTimer')">
          <el-option
              v-for="item in TimerOption"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('immediate.channel')">
        <el-select style="width: 100%;" v-model="form.ChannelId" :placeholder="$t('immediate.placeholderChannel')">
          <el-option
              v-for="item in ChannelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('immediate.rem')">
        <el-input :placeholder="$t('immediate.placeholderRem')" v-model="form.rem" type="textarea"></el-input>
      </el-form-item>
    </el-form>
  </div>
  <div class="common-box">
    <div class="mt20 mb20"><el-button v-loading="loading" size="medium" class="submit-btn" type="primary" @click="submit">{{ $t('immediate.submit') }}</el-button></div>
    <div class="prompt">
      <p style="color: #414141; font-size: 1.4rem">{{ $t('apply.noticeTitle') }}</p>
      <p v-html="content"></p>
    </div>
  </div>
</div>
</template>

<script>

import {defineComponent} from "vue";
import SingleCard from "@/views/member/apply/components/SingleCard.vue";
import AddressSelect from "@/components/AddressSelect.vue";
import { mapGetters } from "vuex"
import {getGoodsType, getTimer} from "@/api/system";
import {arrivalCreateOrder, getChannel} from "@/api/member";

export default defineComponent({
  components: {SingleCard, AddressSelect},
  computed: {
    ...mapGetters(['singleReports', 'GoodsTypeIds']),
    rules() {
      return {
        ChannelId: [{ required: true, message: this.$t('mergerCard.placeholderChannel'), trigger: 'blur' }],
        TimerId: [{ required: true, message: this.$t('mergerCard.placeholderTimer'), trigger: 'blur' }],
      }
    },
  },
  data() {
    return {
      AddrOptions: [],
      TimerOption: [],
      ChannelOptions: [],
      form: {},
      loading: false,
      content: ""
    }
  },
  watch: {
    GoodsTypeIds: {
      handler(newVal) {
        if (newVal.length && this.form.ClientAddressId && this.form.TimerId) {
          this.resetChannel()
        }
      },
      immediate: true
    },
    "form.TimerId": {
      handler(newVal) {
        if (newVal && this.form.ClientAddressId && this.GoodsTypeIds.length) {
          this.resetChannel()
        }
      },
      immediate: true
    }
  },
  async mounted() {
    await this.$store.dispatch('webSite/getShopMatters', 15).then(data => {
      this.content = data.length ? data[0].Content : ""
    })
    this.init().then(() => {
      this.getTimer()
    })
    this.getGoodsType()
  },
  methods: {
    resetChannel() {
      const data = {
        TimerId: this.form.TimerId,
        AddressId: this.form.ClientAddressId,
        GoodsTypeIds: this.GoodsTypeIds
      }
      getChannel(data).then(response => {
        this.$set(this.form, 'ChannelId', undefined)
        this.ChannelOptions = response.Data.ChannelList.map(item => {
          return { label: item.Name, value: item.Id }
        })
        this.$set(this.form, 'ChannelId', response.Data.ChannelList[0].Id)
      })
    },
    getGoodsType() {
      const data = {
        pageIndex: 1,
        pageRows: 10
      }
      getGoodsType(data).then(response => {
        const { Data } = response;
        this.$store.dispatch('consolidation/changeGoodTypes', Data)
        if(!this.singleReports.length) this.addSingle()
      })
    },
    validateSingle() {
      if (!this.singleReports) {
        this.$message({
          type: 'warning',
          message: this.$t('apply.enterInformation')
        });
        return false;
      } else {
        const allValuesAreNotEmpty = this.singleReports.map(item => { return {
          Code: item.Code, GoodsName: item.GoodsName,
          GoodsTypeId: item.GoodsTypeId, Account: item.Account,
          Price: item.Price, TotalPrice: item.TotalPrice } }).every(obj => {
          return Object.values(obj).every(value => value !== null && value !== undefined && value !== "");
        });
        if (!allValuesAreNotEmpty) {
          this.$message({
            type: 'warning',
            message: this.$t('apply.fillInCompleteInformation')
          });
          return false;
        } else {
          return true;
        }
      }
    },
    submit() {
      if (!this.validateSingle()) {
        return;
      }
      let that = this
      this.loading = true
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const data = {
            ParcelInfos: this.singleReports,
            QuoteId: this.form.ChannelId,
            ClientAddressId: this.form.ClientAddressId,
            TimerId: this.form.TimerId,
            Rem: this.form.rem,
          }
          arrivalCreateOrder(data).then(response => {
            const { Code, Msg } = response
            if (Code === 200) {
              this.$message.success(this.$t('immediate.submitSuccess'))
              this.$store.dispatch('consolidation/resetTemp');
              this.form = {}
            } else {
              that.$message.error(Msg);
            }
            that.loading = false
          }).catch(() => { that.loading = false })
        } else {
          this.loading = false
        }
      })
    },
    addSingle() {
      if (this.singleReports.length >= 10) {
        this.$message.warning(this.$t('immediate.addWarning'))
        return;
      }
      this.$store.dispatch('consolidation/addArray')
    },
    getTimer() {
      return new Promise((rs) => {
        const data = {
          PageIndex: 1,
          PageRows: 100
        }
        getTimer(data).then(response => {
          const { Data } = response
          this.TimerOption = Data.map(item => {
            return { label: item.Name, value: item.Id }
          })
          this.$set(this.form, 'TimerId', Data[0].Id)
          rs(true)
        })
      })

    },
    init() {
      return new Promise((rs) => {
        this.$store.dispatch('webSite/getAddress').then(data => {
          this.AddrOptions = data;
          this.AddrOptions.sort((a, b) => {
            if (a.IsDefaultAddress && !b.IsDefaultAddress) return -1;
            if (!a.IsDefaultAddress && b.IsDefaultAddress) return 1;
            return 0;
          });
          const defaults = this.AddrOptions.filter(item => { return item.IsDefaultAddress })
          if (defaults.length) {
            this.$refs.addr.onNav(defaults[0])
          } else if (this.AddrOptions.length) {
            this.$refs.addr.onNav(this.AddrOptions[0])
          } else {
            rs(false)
          }
          rs(true)
        })
      })
    },
    selectAddress(itemId) {
      this.$set(this.form, 'ClientAddressId', itemId)
      if (this.form.TimerId) {
        this.resetChannel()
      }
    },
    removeSingle(){
      this.$store.dispatch('consolidation/removeArray')
    },
  }
})
</script>

<style scoped lang="scss">
.immediate-container {
  padding: 20px;
  .immediate-title {
    padding: 1.5rem 2.5rem;
    color: #225493;
    background-color: #F0F4FB;
    font-size: 1.6rem;
    margin-bottom: 20px;
  }
  .form-box {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px;
    .immediate-form {
      width: 48%;
      padding: 1.2rem;
    }
  }
  .claim-box {
    border: 0.1rem solid #DCDEE2;
    .claim-title {
      display: flex;
      background-color: #F8F8F9;
      border-bottom: 0.1rem solid #DCDEE2;
      padding: 1rem 1.5rem;
      color: #737373;
      font-size: 1.4rem;
    }
    .claim-body {
      padding: 1.5rem;
      .operate {
        width: 100%;
        text-align: right;
        .operate-btn {
          background-color: #194D8F;
          vertical-align: middle;
        }
      }
    }
  }
}
</style>