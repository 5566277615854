<template>
  <div class="core">
    <div class="core-title animate__animated animate__fadeInDown">
      {{ $t('corebox.title') }}
    </div>
    <div class="core-container">
      <div 
        v-for="(item, i) in cores" 
        :key="item.id"
        :style="{ animationDelay: `${ 0.2 * i }s`}" 
        class="core-item animate__animated animate__fadeInUp"
        @mouseenter="handleHover(i)"
        @mouseleave="handleLeave(i)"
      >
        <div class="core-image-wrapper">
          <el-image 
            :src="require(`@/static/images/index/core-box/${item.image}`)" 
            class="core-image"
          ></el-image>
          <div class="core-overlay">
            <el-image 
              :src="require(`@/static/images/${item.icon}`)" 
              class="core-icon"
            ></el-image>
          </div>
        </div>
        <div class="core-introduction">
          <div class="core-title-small">{{ item.title }}</div>
          <div class="core-description">{{ item.notices }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    cores() {
      return [
        {
          id: 1,
          title: this.$t('corebox.title1'),
          image: '1.jpg',
          icon: 'core-logo.png',
          notices: this.$t('corebox.notices1')
        },
        {
          id: 2,
          title: this.$t('corebox.title2'),
          image: '2.jpg',
          icon: 'core-logo.png',
          notices: this.$t('corebox.notices2')
        },
        {
          id: 3,
          title: this.$t('corebox.title3'),
          image: '3.jpg',
          icon: 'core-logo.png',
          notices: this.$t('corebox.notices2'),
        },
        {
          id: 4,
          title: this.$t('corebox.title4'),
          image: '4.jpg',
          icon: 'core-logo.png',
          notices: this.$t('corebox.notices4'),
        }
      ]
    }
  },
  data() {
    return {
      activeIndex: -1
    }
  },
  methods: {
    handleHover(index) {
      this.activeIndex = index
    },
    handleLeave() {
      this.activeIndex = -1
    }
  }
}
</script>

<style scoped lang="scss">
.core {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 6rem;
  padding: 2rem 0;

  .core-title {
    font-size: 3.2rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 5rem;
    background: linear-gradient(45deg, #2196F3, #4CAF50);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 0.9;
  }

  .core-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 3rem;
    width: 100%;
    
    .core-item {
      background-color: #fff;
      border-radius: 1.2rem;
      overflow: hidden;
      box-shadow: 0 10px 20px rgba(0,0,0,0.05);
      transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
      
      &:hover {
        transform: translateY(-10px);
        box-shadow: 0 20px 40px rgba(0,0,0,0.1);

        .core-image {
          transform: scale(1.1);
        }

        .core-overlay {
          opacity: 0.9;
        }
      }

      .core-image-wrapper {
        position: relative;
        height: 24rem;
        overflow: hidden;

        .core-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.6s ease;
        }

        .core-overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(
            rgba(0,0,0,0.3),
            rgba(0,0,0,0.6)
          );
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
          transition: opacity 0.3s ease;

          .core-icon {
            width: 8rem;
            filter: brightness(0) invert(1);
            transform: scale(0.9);
            transition: transform 0.3s ease;
          }
        }
      }

      .core-introduction {
        padding: 2rem;

        .core-title-small {
          font-size: 1.8rem;
          font-weight: 600;
          margin-bottom: 1.4rem;
          color: #333;
        }

        .core-description {
          font-size: 1.4rem;
          line-height: 1.6;
          color: #666;
        }
      }
    }
  }
}

// 添加响应式设计
@media (max-width: 1200px) {
  .core {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .core {
    width: 95%;
    
    .core-title {
      font-size: 2.8rem;
    }
    
    .core-container {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 2rem;
    }
  }
}

// 添加动画类
.animate__animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -40px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInUp {
  animation-name: fadeInUp;
}

.animate__fadeInDown {
  animation-name: fadeInDown;
}
</style>
