<template>
  <div class="point-container">
    <div class="search-section">
      <div class="header">
        <h2 class="section-title">自提点查询</h2>
        <p class="subtitle">快速查找离您最近的自提点</p>
      </div>
      <div class="search-box">
        <el-cascader 
          size="medium"
          v-model="values" 
          clearable 
          @change="handleChange" 
          :props="props"
          :placeholder="请选择自提点所在地区"
        >
          <template #prefix>
            <i class="el-icon-location-outline"></i>
          </template>
        </el-cascader>
      </div>
    </div>

    <div class="grid-container">
      <div class="grid-box" v-loading="loading">
        <item 
          v-for="data in listData" 
          :key="data.Id" 
          :item="data"
          class="grid-item"
        ></item>
      </div>
      
      <el-pagination
        class="pagination"
        background
        layout="prev, pager, next"
        :page-size.sync="pageSize"
        :current-page.sync="pageIndex"
        :current-change="getData"
        hide-on-single-page
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getAgent } from "@/api/member";
import { getCity, getProvince, getArea, getCountry } from "@/api/system";
import { mapGetters } from "vuex"
import Item from "./componetns/item.vue";

export default {
  data() {
    return {
      values: [],
      listData: [],
      pageSize: 10,
      loading: false,
      pageIndex: 1,
      total: 0,
      props: {
        lazy: true,
        checkStrictly: true,
        lazyLoad (node, resolve) {
          const { level, value } = node
          if (level === 0) {
            const data = {
              tenantId: this.TenantId,
              IsAgent: true
            }
            getCountry(data).then(response => {
              const { Data } = response
              resolve(Data.map(item => {
                return {
                  label: `${item.Name}(${item.AgentCount})`,
                  value: item.Id
                }
              }))
            })
          } else if (level === 1) {
            const data = {
              DestinationId: value,
              IsAgent: true
            }
            getProvince(data).then(response => {
              resolve(response.Data.map(item => {
                return {
                  label: `${item.Name}(${item.AgentCount})`,
                  value: item.Id
                }
              }))
            })
          } else if (level === 2) {
            const data = {
              ProvinceId: value,
              IsAgent: true
            }
            getCity(data).then(response => {
              resolve(response.Data.map(item => {
                return {
                  label: `${item.Name}(${item.AgentCount})`,
                  value: item.Id
                }
              }))
            })
          } else if (level === 3) {
            const data = {
              CityId: value,
              IsAgent: true
            }
            getArea(data).then(response => {
              resolve(response.Data.map(item => {
                return {
                  label: `${item.Name}(${item.AgentCount})`,
                  value: item.Id
                }
              }))
            })
          } else {
            resolve([])
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(['TenantId'])
  },
  components: {
    Item
  },
  methods: {
    handleChange() {
      this.getData()
    },
    getData() {
      const data = {
        DestinationId: this.values[0] || undefined,
        ProvinceId: this.values[1] || undefined,
        CityId: this.values[2] || undefined,
        AreaId: this.values[3] || undefined,
        PageIndex: this.pageIndex,
        PageRows: this.pageSize,
      }
      this.loading = true
      getAgent(data).then(response => {
        this.loading = false
        const { Code, Msg, Data, Total } = response
        if (Code === 200) {
          this.listData = Data
          this.total = Total
        } else {
          this.$message.warning(Msg)
        }
      })
    }
  },
  created() {
    this.getData()
  }
}

</script>

<style scoped lang="scss">
.point-container {
  padding: 2rem;
  background-color: #f5f7fa;
  // min-height: 100vh;

  .search-section {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.05);
    margin-bottom: 2rem;
    text-align: center;

    .header {
      margin-bottom: 2rem;
      
      .section-title {
        color: #303133;
        font-size: 2rem;
        margin-bottom: 0.8rem;
        font-weight: 600;
      }

      .subtitle {
        color: #909399;
        font-size: 1.4rem;
        margin: 0;
      }
    }

    .search-box {
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
      
      :deep(.el-cascader) {
        width: 100%;
        
        .el-input__inner {
          height: 40px;
          line-height: 40px;
          font-size: 1.4rem;
          padding-left: 35px;
        }

        .el-input__prefix {
          left: 12px;
          font-size: 1.6rem;
          color: #409EFF;
        }
      }
    }
  }

  .grid-container {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.05);

    .grid-box {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      gap: 2rem;
      margin-bottom: 2rem;
    }

    .pagination {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
    }
  }
}
</style>
