<template>
  <div class="agent-center">
    <div class="title">{{ $t('agent.title') }}</div>
    <div class="agent-box u-event-flex">
      <div class="share-box">
        <div class="qr-code">
          <canvas ref="qrcodeCanvas" :style="canvasStyle"></canvas>
          <div class="mine-code">{{ $t('agent.referralCode') }}：{{ userInfo.Id }}</div>
          <div class="center">
            <el-image :src="require('@/static/images/member/agent/agent.png')" class="center-img"></el-image>
          </div>
        </div>
        <div class="operate-btn">
          <button class="round-btn" @click="downloadQrCode">{{ $t('agent.download') }}</button>
          <button class="round-btn" @click="copyLink">{{ $t('agent.copyLink') }}</button>
        </div>
      </div>
      <div class="show-box">
        <div class="mr15">
          <div class="show-number">
<!--            <CountTo :startVal='0' :endVal='userInfo.WithdrawalMoney' :duration='3000' />-->
            {{ userInfo.WithdrawalMoney }}
          </div>
          <div class="show-title" style="color: #88EBCD">{{ $t('agent.WithdrawalMoney') }}</div>
        </div>
        <el-image :src="require('@/static/images/member/agent/money.png')" class="show-img"></el-image>
        <div class="line" style="background-color: #88EBCD"></div>
      </div>
      <div class="show-box">
        <div>
          <div class="show-number">
            <CountTo :startVal='0' :endVal='promotionNumber' :duration='3000' />
<!--            {{ promotionNumber }}-->
          </div>
          <div class="show-title" style="color:#FCC30E;">{{ $t('agent.promotionNumber') }}</div>
        </div>
        <el-image :src="require('@/static/images/member/agent/vip.png')" class="show-img"></el-image>
        <div class="line" style="background-color: #FCC30E"></div>
      </div>
      <div class="withdrawal-btn" @click="visible=true">{{ $t('agent.withdrawal') }}</div>
    </div>
    <div class="title">{{ $t('agent.member') }}</div>
    <div class="table-box">
      <el-table v-loading="loading" size="mini" :data="listData" :header-cell-style="headerStyle">
        <el-table-column :label="$t('agent.id')" prop="ClientId" align="center"></el-table-column>
        <el-table-column :label="$t('agent.name')" prop="ClientName" align="center"></el-table-column>
        <el-table-column :label="$t('agent.createTime')" prop="CreateTime" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.CreateTime | formatDate }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('agent.lastLoginTime')" prop="LastLoginTime" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.LastLoginTime | formatDate }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="mt20" style="width: 100%; text-align: center">
        <el-pagination
            background
            :current-page.sync="pageIndex"
            layout="prev, pager, next"
            :page-size="pageSize"
            @current-change="handleChange"
            :total="total">
        </el-pagination>
      </div>

    </div>
    <el-dialog :title="$t('agent.withdrawal')" :visible.sync="visible" width="25%">
      <div class="balance u-start-flex">
        <el-image :src="require('@/static/images/member/recharge/money.png')" class="balance-icon"></el-image>
        <div>{{ $t('agent.withdrawalIncome') }}：{{ userInfo.WithdrawalMoney }}
          <span class="pointer" style="color: #2583E5" @click="updateWithdrawalNumber">{{ $t('agent.allWithdrawal') }}</span>
        </div>
      </div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto" class="demo-ruleForm">
        <el-form-item :label="$t('agent.label')" prop="withdrawalNumber">
          <el-input v-model="ruleForm.withdrawalNumber" type="number"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" style="display: flex; justify-content: center; width: 100%">
        <el-button style="width: 40%" v-loading="loadBtnLoading" type="primary" @click="withdraw('ruleForm')">{{ $t('agent.submit') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcode";
import { mapGetters } from "vuex"
import {getAgentList} from "@/api/member";
import {withdrawal} from "@/api/user";
import clipboardCopy from "clipboard-copy";
import CountTo from 'vue-count-to'
export default {
  name: "index",
  data() {
    return {
      visible: false,
      ruleForm: {
        withdrawalNumber: undefined
      },
      loadBtnLoading: false,
      url: "",
      copyLinkUrl: "",
      total: 0,
      promotionNumber: 0,
      pageIndex: 1,
      loading: false,
      pageSize: 10,
      listData: [],
      canvasStyle: {},
      rules: {
        withdrawalNumber: [
          { required: true, message: this.$t('agent.placeholder'), trigger: 'blur' },
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  components: {
    CountTo
  },
  methods: {
    headerStyle() {
      return 'background-color: #DAE5F2'
    },
    downloadQrCode() {
      const canvas = document.createElement('canvas');
      canvas.getContext('2d');

      // 生成二维码到 Canvas
      QRCode.toCanvas(canvas, this.url, function (error) {
        if (error) console.error(error);
        else {
          // 创建下载链接
          const link = document.createElement('a');
          link.href = canvas.toDataURL('image/png'); // 将 Canvas 转为 data URL
          link.download = 'qrcode.png'; // 设置下载文件名

          // 将下载链接添加到页面并模拟点击
          document.body.appendChild(link);
          link.click();

          // 清理下载链接
          document.body.removeChild(link);
        }
      });
    },
    handleChange(current) {
      this.pageIndex = current;
      this.getData()
    },
    copyLink() {
      let that = this;
      clipboardCopy(this.copyLinkUrl)
      .then(() => {
        that.$message({
          message: this.$t('account.copySuccess'),
          type: 'success'
        });
      })
      .catch(err => {
        console.error(this.$t('account.copyTextFailed'), err);
      });
    },
    withdraw(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loadBtnLoading = true;
          const data = {
            ClientId: this.userInfo.Id,
            WithdrawLimit: this.ruleForm.withdrawalNumber,
            Type: 1
          }
          withdrawal(data).then(response => {
            this.loadBtnLoading = false;
            const { Code, Msg, Success } = response
            if (Code === 200 && Success) {
              this.$message.success(this.$t('agent.success'));
              setTimeout(() => {
                this.$store.dispatch('user/getInfo');
                this.visible = false
              }, 1000)
            } else {
              this.$message.warning(Msg)
            }
          }).catch(() => { this.loadBtnLoading = false; })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getData() {
      const data = {
        PageIndex: this.pageIndex,
        PageRows: this.pageSize,
        ClientId: this.userInfo.Id,
        Type: 0
      }
      this.loading = true
      getAgentList(data).then(response => {
        this.loading = false
        const { Code, Data, Msg, Total } = response
        if (Code === 200) {
          const { RecommenderClientCount, GetClientAgentInfo, ShopUrl } = Data
          this.url = `${window.location.protocol}//${ShopUrl}/#/pages/login/register?AgentId=${this.userInfo.Id}`
          this.copyLinkUrl = `${window.location.origin}/#/register?AgentId=${this.userInfo.Id}`
          this.generateQRCode(this.url)
          this.listData = GetClientAgentInfo
          this.promotionNumber = RecommenderClientCount
          this.total = Total
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    updateWithdrawalNumber() {
      this.ruleForm.withdrawalNumber = this.userInfo.WithdrawalMoney;
    },
    generateQRCode(url) {
      const canvas = this.$refs.qrcodeCanvas
      QRCode.toCanvas(canvas, url, {
        errorCorrectionLevel: 'H', // 容错级别，可选值为 'L', 'M', 'Q', 'H'
        margin: 0, // 二维码与边缘的间距
      }, (error) => {
        if (error) {
          console.error(error)
        } else {
          this.canvasStyle = {
            width: '15rem',
            height: '15rem'
          }
          this.$forceUpdate()
        }
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
// 添加一些复用的动画关键帧
@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

@keyframes shine {
  0% { background-position: -100px; }
  100% { background-position: 200px; }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.agent-center {
  background: linear-gradient(135deg, #f6f9fc 0%, #f1f7fb 100%);
  padding: 3rem;
  min-height: 100vh;
  transition: all 0.3s ease;

  .title {
    color: #2c3e50;
    padding-left: 1.5rem;
    border-left: 4px solid #2A82E4;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2.5rem;
    position: relative;
    display: inline-block;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 1.5rem;
      width: 50px;
      height: 2px;
      background: linear-gradient(90deg, #2A82E4, transparent);
    }
  }

  .agent-box {
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px);
    border-radius: 2rem;
    padding: 3rem;
    margin: 2rem 0;
    position: relative;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 2rem;
      border: 2px solid transparent;
      background: linear-gradient(135deg, #2A82E4, #1890ff) border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &:hover::before {
      opacity: 1;
    }

    .withdrawal-btn {
      color: #fff;
      background: linear-gradient(135deg, #2A82E4, #1890ff);
      padding: 1.5rem 3rem;
      border-radius: 1.5rem;
      font-size: 1.6rem;
      font-weight: 600;
      cursor: pointer;
      border: none;
      transition: all 0.3s ease;
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100px;
        width: 50px;
        height: 100%;
        background: rgba(255, 255, 255, 0.3);
        transform: skewX(-20deg);
      }

      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 10px 20px rgba(42, 130, 228, 0.3);
        
        &::before {
          animation: shine 1.5s infinite;
        }
      }
    }

    .show-box {
      background: #fff;
      border-radius: 1.5rem;
      padding: 2rem;
      margin: 1.5rem 0;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
      position: relative;
      overflow: hidden;
      
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(135deg, rgba(42, 130, 228, 0.1), rgba(24, 144, 255, 0.1));
        opacity: 0;
        transition: opacity 0.3s ease;
      }

      &:hover::after {
        opacity: 1;
      }

      .show-number {
        font-size: 2.5rem;
        font-weight: 700;
        background: linear-gradient(135deg, #2A82E4, #1890ff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 0.8rem;
        animation: pulse 2s infinite;
      }

      .show-title {
        font-size: 1.6rem;
        font-weight: 600;
        letter-spacing: 0.5px;
      }

      .show-img {
        width: 6rem;
        filter: drop-shadow(0 5px 10px rgba(0, 0, 0, 0.1));
        animation: float 3s ease-in-out infinite;
      }
    }

    .share-box {
      display: flex;
      align-items: flex-start;
      gap: 1.5rem;

      .qr-code {
        position: relative;
        width: 12rem;
        height: 12rem;
        padding: 1rem;
        background: #fff;
        border-radius: 1.5rem;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
        
        canvas {
          width: 100% !important;
          height: 100% !important;
          border-radius: 0.8rem;
        }

        // 修改中心logo的样式
        .center {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 3rem;
          height: 3rem;
          background: #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.5rem;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
          z-index: 2;

          .center-img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            transition: transform 0.3s ease;

            &:hover {
              transform: rotate(360deg);
            }
          }
        }

        .mine-code {
          position: absolute;
          bottom: -2.5rem;
          left: 50%;
          transform: translateX(-50%);
          white-space: nowrap;
          background: rgba(42, 130, 228, 0.1);
          padding: 0.5rem 1rem;
          border-radius: 1rem;
          font-size: 1.2rem;
          font-weight: 500;
          color: #2A82E4;
        }
      }

      .operate-btn {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        .round-btn {
          min-width: 7rem;
          padding: 0.8rem 1.5rem;
          font-size: 1.3rem;
          font-weight: 500;
          color: #fff;
          background: linear-gradient(135deg, #2A82E4, #1890ff);
          border-radius: 0.8rem;
          border: none;
          cursor: pointer;
          transition: all 0.3s ease;
          position: relative;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0 2px 8px rgba(42, 130, 228, 0.2);
          
          &::before {
            content: '';
            width: 1.2rem;
            height: 1.2rem;
            margin-right: 0.5rem;
            background-size: contain;
            background-repeat: no-repeat;
          }

          &:first-child::before {
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>');
          }

          &:last-child::before {
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>');
          }

          &:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 12px rgba(42, 130, 228, 0.3);
            background: linear-gradient(135deg, #1890ff, #2A82E4);

            &::after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: linear-gradient(
                90deg,
                transparent,
                rgba(255, 255, 255, 0.2),
                transparent
              );
              animation: shine 1s ease-in-out;
            }
          }

          &:active {
            transform: translateY(0);
            box-shadow: 0 2px 4px rgba(42, 130, 228, 0.2);
          }
        }
      }
    }
  }

  .table-box {
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px);
    border-radius: 1.5rem;
    padding: 1.5rem;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
    
    ::v-deep .el-table {
      background: transparent;
      
      th {
        background: rgba(42, 130, 228, 0.05);
        border: none;
        
        &:first-child {
          border-radius: 1rem 0 0 1rem;
        }
        
        &:last-child {
          border-radius: 0 1rem 1rem 0;
        }
      }
      
      td {
        border: none;
        transition: all 0.3s ease;
      }
      
      tr:hover td {
        background: rgba(42, 130, 228, 0.05);
      }
    }
  }
}

// 优化表单样式
::v-deep .el-input__inner {
  font-size: 1.6rem;
  width: 18rem;
  border: none;
  color: #2c3e50;
  border-bottom: 2px solid #2A82E4;
  border-radius: 0;
  height: 3.5rem;
  line-height: 3.5rem;
  transition: all 0.3s ease;
  
  &:focus {
    box-shadow: 0 5px 15px rgba(42, 130, 228, 0.1);
  }
}

::v-deep .el-form-item__label {
  font-size: 1.6rem;
  color: #2c3e50;
  font-weight: 500;
}

// 添加页面进入动画
.agent-center > * {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.5s ease forwards;
  
  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      animation-delay: $i * 0.1s;
    }
  }
}

// 添加一些新的动画
@keyframes scaleIn {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

// 为不同元素添加不同的进入动画
.share-box {
  animation: scaleIn 0.5s ease forwards;
}

.show-box {
  animation: fadeInRight 0.5s ease forwards;
  opacity: 0;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }
}

@keyframes fadeInRight {
  from {
    transform: translateX(20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

// 优化数据展示卡片的样式
.show-box {
  background: linear-gradient(135deg, #fff 0%, #f8f9fa 100%);
  border: 1px solid rgba(42, 130, 228, 0.1);
  
  &:hover {
    border-color: rgba(42, 130, 228, 0.3);
    transform: translateY(-2px);
  }

  .show-number {
    background: linear-gradient(135deg, #2A82E4 0%, #1890ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 2px 4px rgba(42, 130, 228, 0.1);
  }

  .show-title {
    color: #666;
    font-size: 1.4rem;
    font-weight: 500;
  }
}

.balance {
  .balance-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}

// 添加闪光动画
@keyframes shine {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
</style>
