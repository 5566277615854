<template>
  <div class="container">
    <div class="u-between-flex mb30">
      <div style="margin-top: 0" class="notify-title">{{ $t('notify.title') }}</div>
      <div class="u-start-flex">
        <div style="margin-top: 0" class="notify-title">{{ $t('notify.emailmsg') }}</div>
        <span class="notify-msg">{{ $t('notify.unverified') }}</span>
      </div>
    </div>
    <div class="u-between-flex">
      <div v-for="i in [1, 2]" :key="i" class="notify-box">
        <div class="u-between-flex" v-for="j in [1,2,3,4]" :key="j">
          <el-image :src="require('@/static/images/member/notify/notify.png')" class="notify-img" fit="contain"></el-image>
          <div class="u-between-flex notify-flex-box">
            <div class="default-font">{{ $t('notify.latestnotice') }}</div>
            <el-switch v-model="value" active-color="#194D8F" inactive-color="#DCDFE6">
            </el-switch>
          </div>
        </div>
      </div>
    </div>
    <div class="notify-title mb30 mt30">{{ $t('notify.intelligentservice') }}</div>
    <div class="u-between-flex service-card">
      <div class="u-start-flex">
        <el-image :src="require('@/static/images/member/notify/notify.png')" class="notify-img mr30" fit="contain"></el-image>
        <div class="default-font">{{ $t('notify.rem') }}</div>
      </div>
      <el-switch v-model="value" active-color="#194D8F" inactive-color="#DCDFE6"></el-switch>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: false
    }
  }
}

</script>

<style scoped lang="scss">
.container {
  padding: 3rem 2rem;
  max-width: 1200px;
  margin: 0 auto;

  .notify-box {
    padding: 2rem;
    flex-basis: 48%;
    background: #fff;
    border-radius: 1rem;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    }

    .notify-flex-box {
      width: 80%;
      border-bottom: 1px solid #eef0f5;
      padding: 1.8rem 0;
      transition: background-color 0.3s ease;

      &:hover {
        background: #f8f9fc;
      }

      &:nth-child(4),
      &:nth-child(8) {
        border: none;
      }
    }
  }
}

.notify-title {
  font-size: 1.8rem;
  color: #194D8F;
  font-weight: 600;
  position: relative;
  margin-top: 2rem;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 3rem;
    height: 3px;
    background: #194D8F;
    border-radius: 2px;
  }
}

.notify-msg {
  font-size: 1.4rem;
  color: #4FC2E9;
  margin-left: 0.5rem;
  padding: 0.2rem 1rem;
  background: rgba(79, 194, 233, 0.1);
  border-radius: 1rem;
}

.notify-img {
  width: 3.2rem;
  padding: 0.6rem;
  background: #f5f7fa;
  border-radius: 0.8rem;
  transition: all 0.3s ease;

  &:hover {
    background: #e9ecf2;
  }
}

.default-font {
  font-size: 1.5rem;
  color: #2c3e50;
}

.service-card {
  background: #fff;
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  }
}

:deep(.el-switch) {
  margin: 0 1.5rem;
  .el-switch__core {
    border-radius: 1rem;
    
    &:hover {
      opacity: 0.9;
    }
  }
}
</style>
