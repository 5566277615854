<template>
  <el-form v-loading="loading" ref="ruleForm" :rules="rules" label-position="left" :model="form" label-width="100px">
    <div class="head-title" v-if="CouponOption.length">
      <div class="line"></div>
      <div>{{ $t('mergerCard.discountInfo') }}</div>
    </div>
    <div class="u-between-flex" v-if="CouponOption.length">
      <el-form-item :label="$t('mergerCard.Coupon')" prop="CouponId" style="flex-basis: 45%">
        <el-select style="width: 100%;" size="small" @clear="clearCoupon" clearable v-model="form.CouponId" :placeholder="$t('mergerCard.placeholderCoupon')">
          <el-option-group
              v-for="group in couponOptions"
              :key="group.label"
              :label="group.label">
            <el-option
                v-for="item in group.options"
                :disabled="item.disabled"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id">
              <span style="float: left">{{ item.Name }}</span>
              <span v-if="item.disabled" style="float: right; color: #e10c3f; font-size: 13px">
                {{ item.reason }}
              </span>
              <span v-else style="float: right; color: #8492a6; font-size: 13px">{{ $t('mergerCard.preferential') }}：{{ item.Amount }}</span>
            </el-option>
          </el-option-group>
        </el-select>
<!--        <el-select style="width: 100%;" size="small" clearable v-model="form.CouponId" :placeholder="$t('mergerCard.placeholderCoupon')">-->
<!--          <el-option-->
<!--              v-for="item in CouponOption"-->
<!--              :key="item.value"-->
<!--              :label="item.label"-->
<!--              :value="item.value">-->
<!--            <span style="float: left">{{ item.label }}</span>-->
<!--            <span style="float: right; color: #8492a6; font-size: 13px">{{ $t('mergerCard.preferential') }}：{{ item.amount }}</span>-->
<!--          </el-option>-->
<!--        </el-select>-->
      </el-form-item>
      <el-form-item :label="$t('mergerCard.CollectionAmount')" prop="CollectionAmount" style="flex-basis: 45%">
        <el-input type="number" size="mini" v-model="form.CollectionAmount"></el-input>
      </el-form-item>
<!--      <el-form-item :label="`${$t('mergerCard.Integral')}(${$t('mergerCard.available')}${Integral})`" prop="Integral" style="flex-basis: 45%">-->
<!--        <el-input type="number" size="mini" v-model="form.Integral"></el-input>-->
<!--      </el-form-item>-->
    </div>
    <div class="head-title">
      <div class="line"></div>
      <div>{{ $t('mergerCard.otherInfo') }}</div>
    </div>
    <div class="u-between-flex">
      <el-form-item size="small" :label="$t('mergerCard.AddedService')" style="flex-basis: 45%">
        <el-select style="width: 100%;" collapse-tags size="mini" multiple  v-model="form.AddedServiceIds" :placeholder="$t('mergerCard.placeholderAddedService')">
          <el-option
              v-for="item in AddedOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            <span style="float: left; font-size: 1.4rem">{{ item.label }}</span>
            <span style="float: right; color: #8492a6; font-size: 1.3rem">{{ $t('mergerCard.servicePrice') }}：{{ item.price }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="isCollectionAmount" :label="$t('mergerCard.CollectionAmount')" prop="CollectionAmount" style="flex-basis: 45%">
        <el-input type="number" size="small" v-model="form.CollectionAmount"></el-input>
      </el-form-item>
    </div>
    <div class="u-between-flex">
      <el-form-item :label="`${$t('mergerCard.InsuredAmount')}(${form.CurrencyName})`" prop="InsuredAmount" style="flex-basis: 45%">
        <el-input type="number" size="small" v-model="form.InsuredAmount"></el-input>
      </el-form-item>
      <el-form-item :label="$t('mergerCard.IsTax')" prop="IsTax" style="flex-basis: 45%">
        <el-select style="width: 100%;" size="small" :disabled="taxMode === 2" v-model="form.IsTax" :placeholder="$t('mergerCard.placeholderIsTax')">
          <el-option
              v-for="item in TaxOption"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: "PaymentForm",
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    isCollectionAmount: {
      type: Boolean,
      default: false
    },
    CalculateFee: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      AddedOptions: [],
      CouponOption: [],
      loading: false,
      isLock: false
    }
  },
  computed: {
    ...mapGetters(['Integral', 'taxMode']),
    actualPrice() {
      return this.CalculateFee.Freight
      // if (this.form.CouponId) {
      //   const couponAmount = this.CouponOption.filter(item => {
      //     return item.Id === this.form.CouponId
      //   })[0].Amount
      //   return parseFloat(this.CalculateFee.Receivables) + parseFloat(couponAmount)
      // } else {
      //   return this.CalculateFee.Receivables
      // }
    },
    couponOptions() {
      let canBeUsed = []
      let unavailable = []
      this.CouponOption.forEach(item => {
        if (this.actualPrice >= item.MinPrice && this.CalculateFee.CarryWeight >= item.MinWeight) {
          canBeUsed.push(item)
        } else {
          let reason = ""
          if (this.actualPrice < item.MinPrice) {
            reason = `满${item.MinPrice}可用`
          }
          if (this.CalculateFee.CarryWeight < item.MinWeight) {
            reason = `计费重量大于${item.MinWeight}KG可用`
          }
          unavailable.push({
            ...item,
            disabled: true,
            reason: reason
          })
        }
      })
      return [
        {
          label: "可使用优惠券",
          options: canBeUsed
        },
        {
          label: "不可使用优惠券",
          options: unavailable
        }
      ]
    },
    TaxOption() {
      return [
        {
          label: this.$t('mergerCard.yes'),
          value: true
        },
        {
          label: this.$t('mergerCard.no'),
          value: false
        }
      ]
    },
    rules() {
      return {
        IsTax: [{ required: true, message: this.$t('mergerCard.placeholderIsTax'), trigger: 'blur' }],
        // AddedServiceIds: [{ required: true, message: this.$t('mergerCard.placeholderAdded'), trigger: 'blur' }]

      }
    },
  },
  methods: {
    resetCoupon() {
      if (this.isLock) {
        this.isLock = false
        return
      }
      if (this.form.CouponId) {
        const usedOptions = this.couponOptions[0].options.map(item => {
          return item.Id
        })
        if (!usedOptions.includes(this.form.CouponId)) {
          if (this.couponOptions[0].options.length) {
            this.$set(this.form, 'CouponId', this.couponOptions[0].options[0]?.Id)
          }
        }
      } else {
        if (this.couponOptions[0].options.length) {
          this.$set(this.form, 'CouponId', this.couponOptions[0].options[0].Id)
        }
      }
      this.$forceUpdate()
    },
    clearCoupon() {
      this.isLock = true
    },
    async init() {
      this.isLock = false
      await this.$store.dispatch('webSite/getCoupons', { IsUse: 1, IsEffective: 2 }).then(data => {
        this.CouponOption = data
      })
      await this.$store.dispatch('webSite/getAddedService', 1).then(data => {
        this.AddedOptions = data.map(item => {
          return { label: item.Name, value: item.Id, price: item.Price }
        })
      })
    },
    validateIntegral(rule, value, callback) {
      if (value > this.Integral) {
        callback(new Error(this.$t('mergerCard.validateIntegral') || ""));
      } else {
        callback()
      }
    },
    submitForm() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            resolve(true)
          } else {
            reject(false)
          }
        });
      })
    },
  }
}
</script>



<style scoped lang="scss">
.head-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-family: fangsong;
  .line {
    width: 5px;
    height: 15px;
    border-radius: 2.5px;
    margin-right: 8px;
    background: #E4393C;
  }
}
::v-deep .el-form-item__label {
  font-size: 1.4rem;
}
::v-deep .el-form-item {
  margin-bottom: 1rem;
}
::v-deep .el-input__inner {
  height: 3.6rem !important;
}
</style>
