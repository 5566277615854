<template>
  <div class="address-box">
    <div class="head">
      <div style="color: #000; font-weight: 550; width: 16%">{{ $t('address.AddressSelect.title') }}</div>
      <div v-if="chooseAddr" style="width: 70%">{{ chooseAddr }}</div>
      <div class="pointer" style="color: #3C78AB; font-size: 1.4rem;" v-show="expand" @click="handleCreate">{{ $t('address.AddressSelect.add') }}</div>
      <div class="pointer" style="color: #C7C7C7; font-size: 1.6rem" v-show="!expand" @click="expand=true">{{ $t('address.AddressSelect.expand') }}</div>
    </div>
    <transition name="fade">
      <div class="addr-box" 
           v-show="expand"
           v-loading="isLoading"
           element-loading-text="加载地址信息..."
           element-loading-spinner="el-icon-loading"
           element-loading-background="rgba(255, 255, 255, 0.9)">
        <div class="addr-item" v-for="item in options" :key="item.Id">
          <div @click="onNav(item)" class="overview pointer" :class="{ 'btn-active': item.Id == stateCurrent }">
            <div class="badge" v-if="item.Id == stateCurrent">
              <i class="el-icon-check icon-badge"></i>
            </div>
            {{ `${item.RecipientName} ${item.DestinationName}` }}
          </div>
          <div class="addr-detail">
            <div class="address-type" :style="{ background: item.Type ===2 ? '#24ab37' : '#e00101' }">{{ item.TypeText }}</div>
            <el-tooltip class="item" effect="dark" :content="`${item.RecipientName} ${item.DestinationName} ${item.ProvinceName || ''} ${item.CityName || ''} ${item.AreaName || ''} ${item.RecipientAddress}`" placement="right">
              <div class="addr-text">
                {{ `${item.RecipientName} ${item.DestinationName} ${item.ProvinceName || ""} ${item.CityName || ""} ${item.AreaName || ""} ${item.RecipientAddress}` }}
              </div>
            </el-tooltip>
          </div>
          <div class="operation">
            <el-tooltip content="编辑" placement="top">
              <i class="el-icon-edit pointer" @click="handleUpdate(item)"></i>
            </el-tooltip>
            <el-tooltip content="删除" placement="top">
              <i class="el-icon-delete pointer" @click="deleteAddress(item.Id)"></i>
            </el-tooltip>
          </div>
        </div>
      </div>
    </transition>
    <div class="pointer" v-show="expand" style="color: #C7C7C7; font-size: 1.6rem" @click="expand = false">{{ $t('address.AddressSelect.retract') }}<i class="el-icon-arrow-up"></i></div>
    <address-operation
        ref="operation"
        @handle="operationSuccess"
        @close="closeVisible"
        :body="true"
        :show="addressVisible"
        :dialog-status="dialogStatus"
        :temp="temp">
    </address-operation>
  </div>
</template>

<script>
import AddressOperation from "@/components/AddressOperation.vue";
import {deleteAddress} from "@/api/member";
export default {
  name: "AddressSelect",
  props: {
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      stateCurrent: undefined,
      expand: true,
      addressVisible: false,
      dialogStatus: "",
      currentItem: {},
      temp: {},
      isLoading: false,
    }
  },
  computed: {
    chooseAddr() {
      if (this.stateCurrent) {
        let result = ""
        for (let i = 0; i < this.options.length; i++) {
          if (this.options[i].Id === this.stateCurrent) {
            result = `${this.options[i].RecipientName} ${this.options[i].RecipientPhone} ${this.options[i].DestinationName} ${this.options[i].ProvinceName || ""} ${this.options[i].CityName || ""} ${this.options[i].AreaName || ""} ${this.options[i].RecipientAddress}`
          }
        }
        return result
      } else {
        if (Object.keys(this.currentItem).length) {
          return `${this.currentItem.RecipientName} ${this.currentItem.RecipientPhone} ${this.currentItem.DestinationName} ${this.currentItem.ProvinceName || ""} ${this.currentItem.CityName || ""} ${this.currentItem.AreaName || ""} ${this.currentItem.RecipientAddress}`
        } else {
          return ""
        }
      }
    },
  },
  components: {
    AddressOperation
  },
  methods: {
    closeVisible() {
      this.addressVisible = false
    },
    onNav(item) {
      this.$emit('select-address', item.Id)
      this.currentItem = item;
      this.stateCurrent = item.Id
      this.$forceUpdate()
    },
    operationSuccess() {
      this.addressVisible = false
      this.$emit('refresh')
    },
    deleteAddress(rowId) {
      this.$confirm(this.$t('address.confirmDelete'), this.$t('address.common.prompt'), {
        confirmButtonText: this.$t('address.common.confirm'),
        cancelButtonText: this.$t('address.common.cancel'),
        type: 'warning'
      }).then(() => {
        deleteAddress([rowId]).then(response => {
          if (response.Success) {
            this.$message({
              type: 'success',
              message: this.$t('address.deleteSuccess')
            });
            this.$emit('refresh')
            this.onNav({ Id: undefined })
          } else {
            this.$message({
              type: 'warning',
              message: response.Msg
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('domestic.cancelDelete')
        });
      });
    },
    async handleUpdate(row) {
      this.isLoading = true;
      try {
        this.temp = await Object.assign({}, row);
        await this.$refs.operation.getCountry(row.Type === 2);
        await this.$refs.operation.fetchAddress('destination', row['DestinationId']);
        await this.$refs.operation.fetchAddress('province', row['ProvinceId']);
        await this.$refs.operation.fetchAddress('city', row['CityId']);
        if (row.Type === 2) {
          await this.$refs.operation.fetchAddress('area', row['AreaId']);
        }
        this.dialogStatus = 'update';
        this.addressVisible = true;
        this.$nextTick(() => {
          this.$refs.operation.clearValidate();
        });
      } catch (error) {
        this.$message.error('加载地址信息失败');
      } finally {
        this.isLoading = false;
      }
    },
    resetTemp() {
      this.temp = {
        DestinationId: "",
        ProvinceId: "",
        CityId: "",
        Area: "",
        Type: 1,
        AgentPointId: "",
        RecipientName: "",
        RecipientPhone: "",
        RecipientAddress: "",
        serviceType: ""
      }
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.addressVisible = true
      this.$nextTick(() => {
        this.$refs.operation.clearValidate()
      })
    },
  }
}
</script>

<style scoped lang="scss">
/* 定义滚动条的宽度 */
::-webkit-scrollbar {
  width: 10px;
}

/* 定义滚动条轨道的颜色 */
::-webkit-scrollbar-track {
  background: #EBEBEB;
  border-radius: 10px;
}

/* 定义滚动条的颜色 */
::-webkit-scrollbar-thumb {
  background: #69AAD6;
  border-radius: 10px;
}

/* 当鼠标悬停在滚动条上时改变其颜色 */
::-webkit-scrollbar-thumb:hover {
  background: #194D8F;
}
.address-box {
  border: 1px solid #E8E8E8;
  border-radius: 10px;
  padding: 1.6rem;
  margin: 1rem 0;
  font-size: 1.3rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  background: #fff;
  
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
    margin-bottom: 1.6rem;
    padding: 0 0.5rem 0.8rem;
    border-bottom: 1px solid #f0f0f0;

    div:first-child {
      font-size: 1.5rem;
      font-weight: 600;
      color: #2c3e50;
    }
  }

  .addr-box {
    padding: 0 12px;
    height: 16rem;
    overflow-y: auto;
    position: relative;
    
    .addr-item {
      margin: 0.6rem 0;
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      transition: background-color 0.3s ease;
      border: 1px solid transparent;
      
      &:hover {
        background-color: #f8f9fa;
        border-color: #e8e8e8;
      }

      .overview {
        text-align: center;
        border: 2px solid transparent;
        padding: 1rem;
        width: 14rem;
        background-color: #fff;
        position: relative;
        overflow: hidden;
        border-radius: 6px;
        transition: all 0.3s ease;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.03);
        
        &.btn-active {
          border-color: #E4393C !important;
          box-shadow: 0 2px 12px rgba(228, 57, 60, 0.2);
          background-color: #fff9f9;
        }

        .badge {
          position: absolute;
          bottom: 0;
          right: -4rem;
          width: 8rem;
          text-align: center;
          background: linear-gradient(45deg, #E4393C, #ff5a5f);
          transform: rotate(-45deg) translateY(0.4rem);
          transform-origin: bottom center;
          color: #ffffff;
          font-size: 1.3rem;
          
          .icon-badge {
            transform: rotate(45deg);
            padding: 0 5px 5px 0;
          }
        }
      }

      .addr-detail {
        width: calc(100% - 26rem);
        display: flex;
        align-items: center;
        min-width: 0;
        
        .address-type {
          flex: 0 0 auto;
          padding: 3px 10px;
          border-radius: 20px;
          font-size: 1.1rem;
          color: #fff;
          margin-right: 12px;
          font-weight: 500;
          letter-spacing: 0.5px;
          white-space: nowrap;
        }
        
        .addr-text {
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: #4a4a4a;
          font-size: 1.3rem;
          line-height: 1.5;
          min-width: 0;
        }
      }

      .operation {
        width: 7rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 12px;
        margin-left: 10px;
        
        i {
          font-size: 1.6rem;
          color: #909399;
          transition: all 0.3s ease;
          padding: 5px;
          border-radius: 4px;
          
          &:hover {
            background-color: #f0f2f5;
            color: #409EFF;
            
            &.el-icon-delete {
              color: #F56C6C;
            }
          }
        }
      }

      &.is-loading {
        pointer-events: none;
        
        .el-loading-spinner {
          .el-icon-loading {
            font-size: 20px;
            color: #409EFF;
          }
          
          .el-loading-text {
            color: #409EFF;
            font-size: 13px;
            margin-top: 6px;
          }
        }
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.el-loading-mask {
  transition: opacity 0.3s ease;
  backdrop-filter: blur(2px);
}

::v-deep .el-loading-spinner {
  .el-icon-loading {
    font-size: 24px;
    color: #409EFF;
  }
  
  .el-loading-text {
    color: #409EFF;
    font-size: 14px;
    margin-top: 8px;
  }
}
</style>
