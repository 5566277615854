<template>
  <div class="domestic">
<!--    <div class="domestic-left medium-font">-->
<!--      <el-image :src="require('@/static/images/member/personal/domestic.png')" fit="cover" class="domestic-img"></el-image>-->
<!--      <div>{{ $t('personal.domestic') }}</div>-->
<!--    </div>-->
<!--    <div class="vertical-line"></div>-->
    <div>
      <div class="domestic-box u-between-flex default-font">
        <div>
          <div class="title">
            <div class="line"></div>
            <div>{{ $t('personal.package') }}</div>
          </div>
          <div class="u-between-flex">
            <div class="u-start-flex pointer" @click="routerTo('/member/consolidated-domestic?activeName=unarrive')">
              <el-image :src="require('@/static/images/member/personal/undone.png')" fit="cover" class="domestic-box-img"></el-image>
              <div>
                <div class="medium-red-font mb10">{{ countData.NotInCount || 0 }}</div>
                <div>{{ $t('personal.unarrive') }}</div>
              </div>
            </div>
            <div class="small-grid-line"></div>
            <div class="u-start-flex pointer" @click="routerTo('/member/consolidated-domestic?activeName=arrived')">
              <el-image :src="require('@/static/images/member/personal/completed.png')" fit="cover" class="domestic-box-img"></el-image>
              <div>
                <div class="medium-green-font mb10">{{ countData.InCount || 0 }}</div>
                <div>{{ $t('personal.arrived') }}</div>
              </div>
            </div>
            <div class="small-grid-line"></div>
            <div class="u-start-flex pointer" @click="routerTo('/member/consolidated-domestic?activeName=waitPayment')">
              <el-image :src="require('@/static/images/member/personal/undone.png')" fit="cover" class="domestic-box-img"></el-image>
              <div>
                <div class="medium-red-font mb10">{{ countData.WaitOrderCount || 0 }}</div>
                <div>{{ $t('personal.notplaceorder') }}</div>
              </div>
            </div>
            <div class="small-grid-line" v-if="IsMandatoryForecast"></div>
            <div v-if="IsMandatoryForecast" class="u-start-flex pointer" @click="routerTo('/member/consolidated-domestic?activeName=claim')">
              <el-image :src="require('@/static/images/member/personal/completed.png')" fit="cover" class="domestic-box-img"></el-image>
              <div>
                <div class="medium-green-font mb10">{{ countData.AwaitingClaimCount || 0 }}</div>
                <div>{{ $t('personal.placedorder') }}</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="title">
            <div class="line"></div>
            <div>{{ $t('personal.order') }}</div>
          </div>
          <div class="u-between-flex">
            <div class="u-start-flex pointer" @click="routerTo('/member/order-domestic?activeName=unPaid')">
              <el-image :src="require('@/static/images/member/personal/undone.png')" fit="cover" class="domestic-box-img"></el-image>
              <div>
                <div class="medium-red-font mb10">{{ countData.WaitPaymentCount || 0 }}</div>
                <div>{{ $t('personal.pendingPayment') }}</div>
              </div>
            </div>
            <div class="small-grid-line"></div>
            <div class="u-start-flex pointer" @click="routerTo('/member/order-domestic?activeName=underway')">
              <el-image :src="require('@/static/images/member/personal/completed.png')" fit="cover" class="domestic-box-img"></el-image>
              <div>
                <div class="medium-green-font mb10">{{ countData.WaitDeliveryCount || 0 }}</div>
                <div>{{ $t('personal.toBeShipped') }}</div>
              </div>
            </div>
            <div class="small-grid-line"></div>
            <div class="u-start-flex pointer" @click="routerTo('/member/order-domestic?activeName=shipped')">
              <el-image :src="require('@/static/images/member/personal/undone.png')" fit="cover" class="domestic-box-img"></el-image>
              <div>
                <div class="medium-red-font mb10">{{ countData.ShippedCount }}</div>
                <div>{{ $t('personal.shipped') }}</div>
              </div>
            </div>
            <div class="small-grid-line"></div>
            <div class="u-start-flex pointer" @click="routerTo('/member/order-domestic?activeName=completed')">
              <el-image :src="require('@/static/images/member/personal/completed.png')" fit="cover" class="domestic-box-img"></el-image>
              <div>
                <div class="medium-green-font mb10">{{ countData.CompleteCount }}</div>
                <div>{{ $t('personal.completed') }}</div>
              </div>
            </div>
          </div>
        </div>


      </div>
      <div class="u-between-flex mt40">
        <div class="storehouse-btn pointer" @click="$router.push({ path: '/member/address-domestic' })">
          <span>{{ $t('personal.address') }}</span>
        </div>
        <div class="storehouse-btn pointer" @click="$router.push({ path: '/member/claim-domestic' })">
          <span>{{ $t('personal.cargoclaim') }}</span>
        </div>
        <div class="storehouse-btn pointer" @click="$router.push({ path: '/member/consolidated-domestic' })">
          <span>{{ $t('personal.combinedcargo') }}</span>
        </div>
        <div class="storehouse-btn pointer" @click="$router.push({ path: '/member/order-domestic' })">
          <span>{{ $t('personal.myorder') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getConfig} from "@/api/system";
import { mapGetters } from "vuex"
export default {
  props: {
    countData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['TenantId'])
  },
  mounted() {
    getConfig({ TenantId: this.TenantId}).then(response => {
      const { IsMandatoryForecast } = response.Data
      this.IsMandatoryForecast = IsMandatoryForecast
    })
  },
  data() {
    return {
      IsMandatoryForecast: false
    }
  },
  methods: {
    routerTo(url) {
      this.$router.push({
        path: url
      })
    }
  }
}

</script>

<style scoped lang="scss">
.vertical-line {
  width: 0.2rem;
  height: 8rem;
  background-color: #F4F4F4;
}
.domestic {
  padding: 2rem 3rem;

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    
    .line {
      width: 4px;
      height: 18px;
      border-radius: 4px;
      background: linear-gradient(45deg, #bb2232, #ff6b81);
      margin-right: 10px;
      position: relative;
      overflow: hidden;
      
      &::after {
        content: '';
        position: absolute;
        top: -100%;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(transparent, rgba(255,255,255,0.3), transparent);
        animation: shine 2s infinite;
      }
    }
  }

  .domestic-box {
    background: linear-gradient(to bottom right, #fff, #fafbff);
    padding: 2.5rem 3rem;
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(25, 77, 143, 0.06);
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    border: 1px solid rgba(228, 238, 254, 0.6);

    &:hover {
      box-shadow: 0 6px 30px rgba(25, 77, 143, 0.1);
      border-color: rgba(228, 238, 254, 0.9);
    }

    .u-start-flex {
      padding: 1.5rem 2rem;
      margin: 0 1rem;
      border-radius: 12px;
      background: transparent;
      transition: all 0.3s ease;
      position: relative;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        inset: 0;
        border-radius: 12px;
        padding: 1px;
        background: linear-gradient(45deg, transparent, rgba(25, 77, 143, 0.1), transparent);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        opacity: 0;
        transition: opacity 0.3s ease;
      }

      &:hover {
        background: rgba(248, 250, 253, 0.8);
        
        &::before {
          opacity: 1;
        }

        .domestic-box-img {
          filter: brightness(1.1) contrast(1.05);
        }

        .medium-red-font,
        .medium-green-font {
          transform: scale(1.05);
        }
      }

      .domestic-box-img {
        width: 4rem;
        margin-right: 2.5rem;
        transition: all 0.3s ease;
        filter: drop-shadow(0 2px 4px rgba(0,0,0,0.1));
      }

      .medium-red-font,
      .medium-green-font {
        font-size: 2.2rem;
        font-weight: 600;
        transition: all 0.3s ease;
        display: inline-block;
      }

      .medium-red-font {
        color: #ff6b81;
        text-shadow: 0 2px 4px rgba(255,107,129,0.2);
      }

      .medium-green-font {
        color: #00b894;
        text-shadow: 0 2px 4px rgba(0,184,148,0.2);
      }
    }

    .small-grid-line {
      width: 1px;
      height: 4rem;
      background-color: #e8eef8;
      margin: 0 2rem;
    }
  }

  .storehouse-btn {
    background: #fff;
    text-align: center;
    flex-basis: 23%;
    height: 5rem;
    line-height: 5rem;
    color: #194D8F;
    font-size: 1.6rem;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(25, 77, 143, 0.08);
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    border: 1px solid rgba(228, 238, 254, 0.8);
    position: relative;
    overflow: hidden;
    margin: 0 0.8rem;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(45deg, #194D8F, #2980b9);
      opacity: 0;
      transition: opacity 0.3s ease;
      z-index: 1;
    }

    span {
      position: relative;
      z-index: 2;
      transition: color 0.3s ease;
    }

    &:hover {
      border-color: transparent;
      box-shadow: 0 6px 20px rgba(25, 77, 143, 0.15);

      &::before {
        opacity: 0.95;
      }

      span {
        color: #fff;
      }
    }
  }

  .mt40 {
    margin-top: 3.5rem;
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem;
  }

  .mb10 {
    margin-bottom: 0.8rem;
  }
}

@keyframes shine {
  0% {
    top: -100%;
  }
  100% {
    top: 100%;
  }
}

// 添加响应式布局
@media screen and (max-width: 768px) {
  .domestic {
    padding: 1.5rem;

    .domestic-box {
      padding: 2rem;
    }

    .storehouse-btn {
      font-size: 1.4rem;
      height: 4.5rem;
      line-height: 4.5rem;
    }
  }
}
</style>
