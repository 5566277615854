<template>
  <div class="container">
    <div class="title-box">
      <div class="search-box">
        <el-input v-model="billCode" :placeholder="$t('logistics.placeholder')"></el-input>
        <el-button type="primary" class="search-btn" icon="el-icon-search" @click="getData">{{ $t('logistics.search') }}</el-button>
      </div>
      <div class="history-box mt20" v-if="history && history.length">
        <div class="u-start-flex">
          <div>{{ $t('logistics.records') }}</div>
          <div v-for="code in history" :key="code" class="ml30 his" @click="tapHistory(code)">{{ code }}</div>
        </div>
        <el-button class="history-btn" plain size="mini" round icon="el-icon-delete" @click="clear">{{ $t('logistics.clearrecord') }}</el-button>
      </div>
    </div>
    <div class="body-box mt40" v-loading="loading">
      <div v-if="!logisticsData.length" class="empty-state">
        <el-empty
          :image-size="200"
          :description="$t('logistics.noData')"
        />
      </div>
      <div v-else class="logistics-info">
        <div class="logistics-header">
          <div class="status-card">
            <div class="latest-tag">最新物流</div>
            <h3>{{ logisticsData[0].StateName }}</h3>
            <p class="time">{{ logisticsData[0].Datatime | formatDate }}</p>
            <p class="desc">{{ logisticsData[0].Description }}</p>
          </div>
        </div>
        <div class="logistics-timeline">
          <div 
            v-for="(activity, index) in logisticsData.slice(1)" 
            :key="index" 
            class="timeline-item"
            :class="{ 'active': index === 0 }"
          >
            <div class="time-line">
              <span class="dot"></span>
              <span class="line" v-if="index !== logisticsData.length - 2"></span>
            </div>
            <div class="content">
              <div class="time-status">
                <span class="time">{{ activity.Datatime | formatDate }}</span>
                <span class="status-tag">{{ activity.StateName }}</span>
              </div>
              <div class="status">{{ activity.StateName }}</div>
              <div class="description">{{ activity.Description }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryTrackLog } from "@/api/system";
import { mapGetters } from "vuex"
export default {
  data() {
    return {
      billCode: undefined,
      logisticsData: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters(['history', 'TenantId'])
  },
  methods: {
    tapHistory(code) {
      this.billCode = code;
      this.getData()
    },
    addToFront(arr, element) {
      // 检查数组是否为空
      if (arr.length === 0) {
        arr.push(element);
        return arr;
      }

      // 检查元素是否已存在于数组中
      const index = arr.indexOf(element);
      if (index !== -1) {
        // 如果元素存在则将其移动到数组最前面
        arr.splice(index, 1);
        arr.unshift(element);
      } else {
        // 如果元素不存在，则将其添加到数组最前面
        arr.unshift(element);

        // 检查数组长度是否超过6，如果超过则删除最后一个元素
        if (arr.length > 6) {
          arr.pop();
        }
      }
      return arr;
    },
    clear() {
      this.$store.commit('webSite/SET_HISTORY_QUERY', [])
    },
    getData() {
      if (!this.billCode) return;
      const data = {
        PageIndex: 1,
        PageRows: 30,
        OrderCodes: [this.billCode],
        TenantId: this.TenantId
      }
      this.loading = true
      queryTrackLog(data).then(response => {
        this.loading = false
        const { Code, Msg, Data } = response
        const arr = JSON.parse(JSON.stringify(this.history));
        let newArr = this.addToFront(arr, this.billCode);
        this.$store.commit('webSite/SET_HISTORY_QUERY', newArr)
        if (Code === 200) {
          this.logisticsData = Data;
        } else {
          this.$message.warning(Msg)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  .his {
    cursor: pointer;
  }
  .his:hover {
    color: #01A7FF;
  }
  width: 80%;
  margin: 4rem auto;
  min-height: calc(100vh - (10rem + 44rem));
  padding: 4rem 0;
  background-color: #fff;
  .body-box {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    .empty-state {
      margin-top: 4rem;
      padding: 4rem 0;
      border-radius: 12px;
      
      :deep(.el-empty) {
        .el-empty__description {
          margin-top: 2rem;
          color: #606266;
          font-size: 1.4rem;
        }
      }
    }
  }
  .title-box {
    width: 80%;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);

    .search-box {
      display: flex;
      align-items: center;
      gap: 1.5rem;

      .el-input {
        flex: 1;
        
        :deep(.el-input__inner) {
          border-radius: 8px;
          height: 44px;
          font-size: 1.4rem;
          transition: all 0.3s;
          
          &:focus {
            box-shadow: 0 0 0 2px rgba(25, 77, 143, 0.1);
          }
        }
      }

      .search-btn {
        background-color: #194D8F;
        min-width: 12rem;
        height: 44px;
        border-radius: 8px;
        font-size: 1.4rem;
        transition: all 0.3s;
        
        &:hover {
          background-color: #1a5ba8;
        }
      }
    }

    .history-box {
      margin-top: 2rem;
      padding-top: 2rem;
      border-top: 1px solid #f0f0f0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .u-start-flex {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;

        > div:first-child {
          color: #666;
          font-weight: 500;
        }

        .his {
          padding: 0.4rem 1.2rem;
          background: #f5f7fa;
          border-radius: 20px;
          font-size: 1.3rem;
          color: #606266;
          transition: all 0.3s;
          
          &:hover {
            background: #e6f7ff;
            color: #01A7FF;
          }
        }
      }

      .history-btn {
        padding: 0.4rem 1.2rem;
        border-color: #dcdfe6;
        
        &:hover {
          color: #f56c6c;
          border-color: #f56c6c;
        }

        .el-icon-delete {
          margin-right: 4px;
        }
      }
    }
  }
}

.logistics-info {
  margin-top: 2rem;
  text-align: left;
  
  .logistics-header {
    margin-bottom: 3rem;
    
    .status-card {
      position: relative;
      background: linear-gradient(135deg, #194D8F 0%, #2171d5 100%);
      color: white;
      padding: 2rem;
      border-radius: 12px;
      box-shadow: 0 8px 24px rgba(25, 77, 143, 0.12);
      
      .latest-tag {
        position: absolute;
        right: 2rem;
        top: 2rem;
        font-size: 1.2rem;
        padding: 0.4rem 1rem;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 12px;
      }
      
      h3 {
        font-size: 1.8rem;
        margin: 0 0 1rem;
        font-weight: 600;
      }
      
      .time {
        font-size: 1.4rem;
        opacity: 0.9;
        margin-bottom: 0.5rem;
      }
      
      .desc {
        font-size: 1.4rem;
        opacity: 0.8;
        line-height: 1.6;
      }
    }
  }
  
  .logistics-timeline {
    padding: 0 1rem;
    
    .timeline-item {
      display: flex;
      gap: 2rem;
      position: relative;
      padding-bottom: 2rem;
      
      &.active {
        .dot {
          background-color: #194D8F;
          border-color: #e6f7ff;
        }
        
        .content {
          .time-status .status-tag {
            color: #194D8F;
            background: #e6f7ff;
          }
        }
      }
      
      .time-line {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 6px;
        
        .dot {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #fff;
          border: 2px solid #dcdfe6;
          z-index: 1;
          transition: all 0.3s ease;
        }
        
        .line {
          position: absolute;
          top: 12px;
          width: 2px;
          height: calc(100% - 6px);
          background-color: #dcdfe6;
        }
      }
      
      .content {
        flex: 1;
        
        .time-status {
          display: flex;
          align-items: center;
          gap: 1rem;
          margin-bottom: 0.5rem;
          
          .time {
            font-size: 1.3rem;
            color: #909399;
          }
          
          .status-tag {
            font-size: 1.2rem;
            padding: 0.2rem 0.8rem;
            background: #f5f7fa;
            border-radius: 12px;
            color: #606266;
            transition: all 0.3s ease;
          }
        }
        
        .status {
          font-size: 1.4rem;
          color: #303133;
          font-weight: 500;
          margin-bottom: 0.5rem;
        }
        
        .description {
          font-size: 1.4rem;
          color: #606266;
          line-height: 1.6;
          padding-right: 2rem;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .logistics-info {
    .logistics-header {
      .status-card {
        .latest-tag {
          font-size: 1.2rem;
        }
      }
    }
    
    .logistics-timeline {
      .timeline-item {
        .time-line {
          .dot {
            width: 10px;
            height: 10px;
          }
        }
      }
    }
  }
}
</style>
