<template>
  <div class="container">
    <div class="title-box">
      <div class="search-box">
        <el-input v-model="billCode" :placeholder="$t('logistics.placeholder')"></el-input>
        <el-button type="primary" class="search-btn" icon="el-icon-search" @click="getData">{{ $t('logistics.search') }}</el-button>
      </div>
      <div class="history-box mt20">
        <div class="u-start-flex">
          <div>{{ $t('logistics.records') }}</div>
          <div v-for="code in history" :key="code" class="ml30 his" @click="tapHistory(code)">{{ code }}</div>
        </div>
        <el-button class="history-btn" plain size="mini" round icon="el-icon-delete" @click="clear">{{ $t('logistics.clearrecord') }}</el-button>
      </div>
    </div>
    <div class="body-box mt40" v-loading="loading">
      <el-image v-if="!logisticsData.length" :src="require('@/static/images/query/not_query.png')" class="not-query-img"></el-image>
      <el-timeline style="text-align: left; margin-top: 2rem" v-else>
        <el-timeline-item v-for="(activity, index) in logisticsData" :key="index" :timestamp="activity.Datatime" placement="top">
          <el-card>
            <h4>{{ activity.StateName }}</h4>
            <p>{{ activity.Datatime| formatDate }}----{{ activity.Description }}</p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
    <lang-select class="right-menu-item hover-effect"></lang-select>
  </div>
</template>

<script>
import { queryTrackLog } from "@/api/system";
import { mapGetters } from "vuex"
import LangSelect from "@/components/LangSelect/index.vue";
export default {
  data() {
    return {
      billCode: undefined,
      logisticsData: [],
      loading: false
    }
  },
  components: {
    LangSelect
  },
  computed: {
    ...mapGetters(['history', 'TenantId'])
  },
  created() {
    document.title = "物流轨迹查询"
  },
  methods: {
    tapHistory(code) {
      this.billCode = code;
      this.getData()
    },
    addToFront(arr, element) {
      // 检查数组是否为空
      if (arr.length === 0) {
        arr.push(element);
        return arr;
      }

      // 检查元素是否已存在于数组中
      const index = arr.indexOf(element);
      if (index !== -1) {
        // 如果元素存在，则将其移动到数组最前面
        arr.splice(index, 1);
        arr.unshift(element);
      } else {
        // 如果元素不存在，则将其添加到数组最前面
        arr.unshift(element);

        // 检查数组长度是否超过6，如果超过则删除最后一个元素
        if (arr.length > 6) {
          arr.pop();
        }
      }
      return arr;
    },
    clear() {
      this.$store.commit('webSite/SET_HISTORY_QUERY', [])
    },
    getData() {
      if (!this.billCode) return;
      const data = {
        PageIndex: 1,
        PageRows: 30,
        OrderCodes: [this.billCode],
        TenantId: this.TenantId
      }
      this.loading = true
      queryTrackLog(data).then(response => {
        this.loading = false
        const { Code, Msg, Data } = response
        const arr = JSON.parse(JSON.stringify(this.history));
        let newArr = this.addToFront(arr, this.billCode);
        this.$store.commit('webSite/SET_HISTORY_QUERY', newArr)
        if (Code === 200) {
          this.logisticsData = Data;
        } else {
          this.$message.warning(Msg)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .language-img {
  width: 4rem;
  height: 4rem;
}
.container {
  position: relative;
  .right-menu-item {
    position: absolute;
    right: 5rem;
    top: 4rem;
  }
  .hover-effect {
    cursor: pointer;
  }
  .his {
    cursor: pointer;
  }
  .his:hover {
    color: #01A7FF;
  }
  width: 80%;
  margin: 4rem auto;
  min-height: calc(100vh - (10rem + 44rem));
  padding: 4rem 0;
  background-color: #fff;
  .body-box {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    .not-query-img {
      width: 40%;
    }
  }
  .title-box {
    width: 80%;
    margin: 0 auto;
    .search-box {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .search-btn {
        background-color: #194D8F;
        width: 12rem;
        margin-left: 3rem;
      }
    }
    .history-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
