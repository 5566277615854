<template>
  <div class="container">
    <div class="head">
      <div class="title">
        {{ $t('personal.title') }}
      </div>
    </div>
    <div class="address-list">
      <div class="item" v-for="item in virtualWareHouses" :key="item.Id">
        <div class="warehouse">
          <el-image :src="require('@/static/images/member/personal/warehouse.png')" class="warehouse-img"></el-image>
          <div class="warehouse-name">{{ item.Name }}</div>
        </div>
        <div class="address-box">
          <div class="info-grid">
            <div class="info-item">
              <span class="label-text">{{ $t('personal.ContactPerson') }}</span>
              <div class="value-box">
                <span class="value-text">{{ item.ContactPerson }}</span>
                <el-tooltip :content="$t('personal.clickToCopy')" placement="top">
                  <i class="el-icon-copy-document copy-icon" @click="copyAddress(item.ContactPerson)"></i>
                </el-tooltip>
              </div>
            </div>
            <div class="info-item">
              <span class="label-text">{{ $t('personal.Phone') }}</span>
              <div class="value-box">
                <span class="value-text">{{ item.Phone }}</span>
                <el-tooltip :content="$t('personal.clickToCopy')" placement="top">
                  <i class="el-icon-copy-document copy-icon" @click="copyAddress(item.Phone)"></i>
                </el-tooltip>
              </div>
            </div>
            <div class="info-item">
              <span class="label-text">{{ $t('personal.PostCode') }}</span>
              <div class="value-box">
                <span class="value-text">{{ item.PostCode }}</span>
                <el-tooltip :content="$t('personal.clickToCopy')" placement="top">
                  <i class="el-icon-copy-document copy-icon" @click="copyAddress(item.PostCode)"></i>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="detail-address">
            <div class="address-label">
              <span class="label-text">{{ $t('personal.DetailedAddress') }}</span>
            </div>
            <div class="address-content">
              <span class="value-text">{{ item.DetailedAddress }}</span>
              <el-tooltip :content="$t('personal.clickToCopy')" placement="top">
                <i class="el-icon-copy-document copy-icon" @click="copyAddress(item.DetailedAddress)"></i>
              </el-tooltip>
            </div>
          </div>
          <div class="remark" v-if="item.Rem">
            <div class="remark-label">
              <span class="label-text">{{ $t('personal.Rem') }}</span>
            </div>
            <div class="remark-content">
              <span class="value-text">{{ item.Rem }}</span>
            </div>
          </div>
          <div class="button-wrapper">
            <el-button type="primary" class="copy-all-btn" @click="copyAddress(item)">
              <i class="el-icon-document-copy"></i>
              {{ $t('personal.copyAddress') }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import {getVirtualWareHouse} from "@/api/member";
import clipboardCopy from "clipboard-copy";
export default {
  data() {
    return {
      virtualWareHouses: []
    }
  },
  computed: {
    ...mapGetters(['shopID'])
  },
  methods: {
    copyAddress(item) {
      if (typeof item === 'object') {
        const copyStr = `${item.ContactPerson}，${item.Phone}，${item.DetailedAddress}，${item.PostCode}`
        let that = this;
        clipboardCopy(copyStr)
            .then(() => {
              that.$message({
                message: this.$t('account.copySuccess'),
                type: 'success'
              });
            })
            .catch(err => {
              console.error(this.$t('account.copyTextFailed'), err);
            });
      } else {
        let that = this;
        clipboardCopy(item)
            .then(() => {
              that.$message({
                message: this.$t('account.copySuccess'),
                type: 'success'
              });
            })
            .catch(err => {
              console.error(this.$t('account.copyTextFailed'), err);
            });
      }
    },
    getData() {
      const data = {
        PageIndex: 1,
        PageRows: 10,
        ShopId: this.shopID
      }
      getVirtualWareHouse(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.virtualWareHouses = Data
        } else {
          this.$message.warning(Msg)
        }
      })
    }
  },
  created() {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.container {
  padding: 2rem;
  background-color: #f5f7fa;
  min-height: 100vh;

  .head {
    background-color: #fff;
    border-radius: 8px;
    padding: 1.5rem 2rem;
    margin-bottom: 2rem;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.05);

    .title {
      font-size: 1.8rem;
      font-weight: 600;
      color: #303133;
      padding-left: 1rem;
      border-left: 4px solid #409EFF;
    }
  }

  .address-list {
    .item {
      background-color: #fff;
      border-radius: 8px;
      padding: 2rem;
      display: flex;
      margin-bottom: 2rem;
      box-shadow: 0 2px 12px 0 rgba(0,0,0,0.05);
      transition: all 0.3s ease;

      &:hover {
        //transform: translateY(-2px);
        box-shadow: 0 4px 16px 0 rgba(0,0,0,0.1);
      }

      .warehouse {
        width: 20%;
        padding-right: 2rem;
        border-right: 1px solid #EBEEF5;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .warehouse-img {
          width: 3rem;
          height: 3rem;
          margin-bottom: 1rem;
        }

        .warehouse-name {
          font-size: 1.6rem;
          color: #303133;
          font-weight: 500;
        }
      }

      .address-box {
        flex: 1;
        padding-left: 3rem;
        padding-right: 2rem;

        .info-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 2.5rem;
          margin-bottom: 2.5rem;
        }

        .info-item {
          background-color: #f8fafc;
          padding: 1.5rem;
          border-radius: 8px;
          transition: all 0.3s ease;

          &:hover {
            background-color: #f0f5ff;
          }
        }

        .label-text {
          color: #606266;
          font-size: 1.4rem;
          display: block;
          margin-bottom: 0.8rem;
        }

        .value-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .value-text {
          color: #409EFF;
          font-size: 1.4rem;
          font-weight: 500;
          word-break: break-all;
        }

        .copy-icon {
          color: #909399;
          font-size: 1.6rem;
          cursor: pointer;
          padding: 0.5rem;
          border-radius: 4px;
          transition: all 0.3s;

          &:hover {
            color: #409EFF;
            background-color: rgba(64, 158, 255, 0.1);
          }
        }

        .detail-address, .remark {
          background-color: #f8fafc;
          border-radius: 8px;
          padding: 1.5rem;
          margin-bottom: 2rem;
          transition: all 0.3s ease;

          &:hover {
            background-color: #f0f5ff;
          }

          .address-label, .remark-label {
            margin-bottom: 1rem;
          }

          .address-content, .remark-content {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            
            .value-text {
              flex: 1;
              margin-right: 1.5rem;
              line-height: 1.6;
            }
          }
        }

        .button-wrapper {
          display: flex;
          justify-content: center;
          padding: 1rem 0;

          .copy-all-btn {
            padding: 1.2rem 4rem;
            font-size: 1.4rem;
            border-radius: 6px;
            transition: all 0.3s;
            
            &:hover {
              transform: translateY(-2px);
              box-shadow: 0 4px 12px rgba(64, 158, 255, 0.3);
            }

            i {
              margin-right: 0.8rem;
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }
}
</style>
