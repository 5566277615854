<template>
  <div>{{ text }}</div>
</template>

<script>
export default {
  name: "LinearGradientText",
  props: {
    text: {
      type: String,
      default: ""
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  font-size: 4vw;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: transparent;
  background: linear-gradient(45deg, 
    #ff6b6b,
    #4ecdc4,
    #45b7d1,
    #96c93d,
    #ff7eb3
  );
  background-size: 300% 300%;
  background-clip: text;
  -webkit-background-clip: text;
  animation: gradient 3s ease infinite;
  
  @media screen and (min-width: 1400px) {
    font-size: 3.2rem;
  }
  
  @media screen and (max-width: 768px) {
    font-size: 5vw;
  }
  
  @media screen and (max-width: 480px) {
    font-size: 6vw;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
